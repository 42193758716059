import React, {  useEffect, useRef, useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Helmet } from 'react-helmet';

import Popup from 'reactjs-popup';
import PopupForm from './PopupForm'; // Adjust the path as per your project structure

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";
// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';

// ----------------Gallery slider import------------------
import ImageGallery from 'react-image-gallery';
import { ModalProvider } from 'styled-react-modal';
import { Button, Modal } from 'antd';
import RegisterModal from '../Popup/RegisterModal';

//--------------------Faq Accodian------------------ 
// ----------------Gallery slider start------------------
const images = [
    {
        original: '/assets/images/updated_image/1.jpg',
        thumbnail: '/assets/images/updated_image/1.jpg',
    },
    {
        original: '/assets/images/updated_image/2.jpg',
        thumbnail: '/assets/images/updated_image/2.jpg',
    },
    {
        original: '/assets/images/updated_image/3.jpg',
        thumbnail: '/assets/images/updated_image/3.jpg',
    },
    {
        original: '/assets/images/updated_image/4.jpg',
        thumbnail: '/assets/images/updated_image/4.jpg',
    },
];
// ----------------Gallery slider end------------------ 

function Young_Leaders_Bootcamp() {

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };


    // const handleMouseEnter = (event) => {
    //     event.target.play();
    //   };
    
    //   const handleMouseLeave = (event) => {
    //     event.target.pause();
    //   };

    const [isMuted, setIsMuted] = useState(true);
    const [isActive, setActive] = useState("false");

    const ToggleClass = () => {
        setActive(!isActive);
    };
    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const showModal = () => {
    //     setIsModalOpen(true);
    // }; 
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // }; 
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };  
  //Video on Hover Start
  

  const [showIframe, setShowIframe] = useState(false);
  const [playingVideos, setPlayingVideos] = useState(new Set());
  
  // Replace with actual logic to determine if the device is mobile or touch
  // Use window.matchMedia to determine if the viewport is mobile-sized
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  useEffect(() => {
    if (isMobile) {
      const videos = document.querySelectorAll('.video');
      const observerOptions = {
        root: null, // Use the viewport as the container
        rootMargin: '0px',
        threshold: 0.5, // Adjust this as needed
      };

      const handleIntersection = (entries) => {
        entries.forEach(entry => {
          const video = entry.target;
          if (entry.isIntersecting) {
            if (video.paused) {
              // Attempt to play the video and handle cases where play() might not return a Promise
              const playPromise = video.play();
              if (playPromise !== undefined) {
                playPromise.catch(error => console.error('Play interrupted:', error));
              }
              setPlayingVideos(prev => new Set(prev.add(video)));
            }
          } else {
            if (!video.paused) {
              // Attempt to pause the video and handle cases where pause() might not return a Promise
              const pausePromise = video.pause();
              if (pausePromise !== undefined) {
                pausePromise.catch(error => console.error('Pause interrupted:', error));
              }
              setPlayingVideos(prev => {
                const updated = new Set(prev);
                updated.delete(video);
                return updated;
              });
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, observerOptions);
      videos.forEach(video => {
        observer.observe(video);
      });

      return () => {
        videos.forEach(video => {
          observer.unobserve(video);
        });
      };
    }
  }, [isMobile, playingVideos]);

  const handleMouseEnter = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (video.paused) {
        // Attempt to play the video and handle cases where play() might not return a Promise
        video.muted = false; // Unmute the video
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => console.error('Play interrupted:', error));
        }
      }
    }
  };

  const handleMouseLeave = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (!video.paused) {
        // Attempt to pause the video and handle cases where pause() might not return a Promise
        const pausePromise = video.pause();
        if (pausePromise !== undefined) {
          pausePromise.catch(error => console.error('Pause interrupted:', error));
        }
        video.muted = true; // Mute the video
      }
    }
  };




  //Video on Hover End

    return (
        <>
        
<Helmet>
        <title>MU20 YLB        </title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
            <Header />
        <div className="green_theme">
        
             {/* <!-- banner section --> */} 
             <section className="oxfordBanner">
                {/* <div style={{ paddingBottom: "56.25%", maxWidth: "100%", position: "relative" }}><iframe src="https://player.vimeo.com/video/347119375?autoplay=1&loop=1&muted=1&title=0&portrait=0&byline=0&keyboard=0" width="800px" height="450px" style={{ position: " absolute", top: "0px", left: "0px", width: "100%", height: "100%" }} frameBorder="0"></iframe></div> */}
                <div>
                    <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false}>
                        {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
                        <source src="https://musite.b-cdn.net/bootcampsmainpage021920X900(1).mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="banner-content">
                    <h1>Young Leaders' Bootcamp</h1>
                    <div className={isActive ? "mute" : "unmute"}>
                        <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/mute.svg" alt="unMuteIcon" />
                            <img className="muteIcon" src="/assets/images/unmute.svg" alt="MuteIcon" /></a>
                    </div>
                </div>
            </section >
            {/* <!-- introduction --> */}
            < section className="intro-section" >
                <div className="container">
                    <div className="introduction">
                        <h2 className="secTitle">Introduction</h2>
                        <p>The Young Leaders' Bootcamp (YLB) at MU20 School of Opportunity is a transformative program designed to cultivate the next generation of leaders. YLB empowers students to develop essential leadership skills, enhance their personal growth, and build a strong foundation for future success. Participants will engage in team-building exercises, strategic thinking sessions, and collaborative projects, all while being guided by experienced mentors. </p>
                    </div>
                </div>
            </section >
            {/* <!-- Why OxfordMUN India ? --> */}
            < section className="whyOxfordSec " >
                <div className="container">
                    <div className="oxford-mun">
                        <h2 className="secTitle">Why choose YLB ?</h2>
                    </div>
                    <div className="inner-box">
                        <div className="row">
                            <div className="column">
                                <div className="oxford-box">
                                    <h3>Comprehensive Growth</h3>
                                    <p>YLB ensures your development is well-rounded by blending academic challenges with physical activities and personal growth exercises. This holistic approach helps you build essential skills like critical thinking, problem-solving, and emotional intelligence, essential for any future leader.</p>
                                </div>
                                <div className="oxford-box">
                                    <h3>Practical Experience</h3>
                                    <p>Immerse yourself in real-world situations through hands-on workshops, leadership simulations, and collaborative projects. These activities are designed to sharpen your leadership skills and prepare you for real-life challenges, making you more adaptable and effective in various scenarios.</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="oxford-box">
                                    <h3>Cultural Exposure
                                    </h3>
                                    <p>Dive into the rich cultures of India, enhancing your global awareness and appreciation for diversity. Interacting with local communities and experiencing their traditions adds a valuable dimension to your learning journey, broadening your perspective and understanding of global issues.
                                    </p>
                                </div>
                                <div className="oxford-box">
                                    <h3>Innovative Learning</h3>
                                    <p>Our curriculum combines modern teaching techniques with experiential learning, making education engaging and impactful. YLB goes beyond traditional methods to offer a unique and stimulating educational experience, ensuring that learning is both enjoyable and effective.</p>
                                </div>


                                


                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* <!-- Conference Details --> */}
            < section className="conference" >
            <div className="container">
                    <div className="confere-detail">
                        <h2 className="secTitle">Bootcamp Details</h2>

                        <div className="row">
                            <div className="column">
                                <div className="confere-box">
                                    <h3>Who</h3>
                                    <p>Students studying in classes <span className="bgColorText">6th to 8th </span>can register</p>

                                </div>
                                <div className="confere-box m-0">
                                    <h3>When and Where</h3>
                                   
                                    <p><span className="bgColorText"> House of Lalpur, Punjab</span></p>
                                    
                                </div>
                            </div>
                            <div className="column confere-reg">
                                <div className="confere-regInner">
                                    <h3>HOW TO REACH THE VENUE: </h3>
                                    <p>The House of Lalpur is located in the Rupnagar district of Punjab, close to the border with Himachal Pradesh, nestled in the Sutlej valley between the Himalayas and the Shivalik mountains. Here’s how you can reach us:
                                        <br/><br/>
                                        <ul>
                                            <li><span className="bgColorText">By Air : </span> The nearest airport is Chandigarh Airport, approximately 82 kilometers away, which takes about 1.5 hours by car.</li>
                                            <li><span className="bgColorText">By Train : </span> The nearest railway station is Anandpur Sahib, around 19 kilometers from the venue, taking approximately 20 minutes to reach. The venue can also be reached via Chandigarh Railway Station, around 86 kilometers from the venue, taking approximately 2 hours by car.</li>
                                            <li><span className="bgColorText">By Road : </span> The House of Lalpur is easily accessible via the Chandigarh-Manali Highway, situated just 5 kilometers off the main road. It is approximately a 1.5-hour drive from Chandigarh and around 7 hours from New Delhi.</li>
                                        </ul>

                                        We recommend arranging transportation from Chandigarh for a convenient and comfortable journey to the House of Lalpur.
                                    </p>

                                    <button className="registerBtn"  onClick={togglePopup} >
                                    Register Now
                            </button>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}

                                    {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> Register Now </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="whyOxfordSec green_bg eventSec">
                <div className="container">
                    <div className="oxford-mun">
                        <h2 className="secTitle">Events</h2>
                    </div>
                    <Swiper
                        breakpoints={{
                            1440: {
                                spaceBetween: 50,
                                slidesPerView: 3
                            },
                            768: {
                                spaceBetween: 40,
                                slidesPerView: 2,
                                centeredSlides: false,
                            },
                            200: {
                                spaceBetween: 20,
                                slidesPerView: 1,
                            }
                        }}
                        // install Swiper modules
                        modules={[Navigation, Autoplay, A11y]}
                        spaceBetween={60}
                        slidesPerView={3}
                        navigation
                        loop={true}
                        autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                          }}
                        centeredSlides={true}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="assets/images/pillarimage/kila_quest.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Kila Quest</h5>
                                    <p>Embark on a thrilling scavenger hunt where teams solve riddles based on previous teachings to find a particular object, testing problem-solving skills and teamwork.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/ScholarsEventR-min.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">OxfordMUN Innovation Challenges</h5>
                                    <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                        thinking.</p>
                                </div>
                            </div>
                        </SwiperSlide> */}
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/superhero.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">The Superhero Project</h5>
                                    <p>Unleash your creativity with the Superhero Project, where you design your own superhero, complete with unique powers and missions. Collaborate with peers to develop your superhero's universe, forge alliances, and tackle global challenges, enhancing your teamwork and imaginative thinking.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="assets/images/pillarimage/trek_to_triumph.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Trek to Triumph </h5>
                                    <p>Navigate challenging terrains, boost your physical endurance, and enjoy the beauty of nature while building resilience and teamwork</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="assets/images/pillarimage/aqua_adventure.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Aqua Adventures</h5>
                                    <p>Dive into kayaking and various water sports on the serene backwaters of the Sutlej River, promoting physical fitness and a love for nature in a safe environment.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/horiticulture_harmony.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Horticulture Harmony</h5>
                                    <p>Immerse yourself in organic farming practices at our horticulture farm, learning about sustainable agriculture while enjoying the tranquil rural setting.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                        


                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/sip&spot.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Sip and Spot </h5>
                                    <p>Savor the finest tea in the peaceful Sutlej river valley while birdwatching, spotting a variety of exotic bird species in their natural habitat.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/leadership_workshop.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Leadership WORKSHOPS</h5>
                                    <p>Explore the core principles of effective leadership through interactive sessions and real-world scenarios. Learn how to inspire, motivate, and guide your team to success, while developing a personal leadership style that is both authentic and impactful.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/teamworkworkshops.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Teamwork WORKSHOPS</h5>
                                    <p>Discover the power of collaboration and the importance of building strong, cohesive teams. Engage in team-building exercises that enhance communication, trust, and cooperation, essential for achieving common goals and driving group success.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/decisionmaking.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Decision Making WORKSHOPS </h5>
                                    <p>Enhance your decision-making skills with practical strategies and tools. Learn to analyze situations, weigh options, and make informed choices quickly and effectively, even under pressure, ensuring the best outcomes for your team.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/pillarimage/problemsolvingworkshop.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                    <h5 className="eventTitle">Problem Solving WORKSHOPS</h5>
                                    <p>Develop your problem-solving abilities through hands-on activities and critical thinking exercises. Gain techniques to identify issues, brainstorm solutions, and implement strategies that address challenges efficiently and creatively.</p>
                                </div>
                            </div>
                        </SwiperSlide>


                    </Swiper>


                    {/* <div className="row">
                        <div className="eventBox">
                            <img src="/assets/images/event1.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Model United Nations</h5>
                                <p>Delve into pressing global issues through meticulously curated committee sessions.</p>
                            </div>
                        </div>
                        <div className="eventBox">
                            <img src="/assets/images/event2.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Oxford Scholars Challenge</h5>
                                <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                    thinking.</p>
                            </div>
                        </div>
                        <div className="eventBox">
                            <img src="/assets/images/event3.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Training Sessions</h5>
                                <p>Led by experts from Oxford, these sessions are designed to prime delegates for success.</p>
                            </div>
                        </div>
                    </div> */}

                </div>
            </section>
            {/* <section className="partnerSec">
                <div className="container-full">
                    <h2 className="secTitle">Partners</h2>

                    <Swiper breakpoints={{
                        1440: {
                            spaceBetween: 25,
                            slidesPerView: 9,
                        },
                        768: {
                            spaceBetween: 20,
                            slidesPerView: 7,

                        },
                        200: {
                            spaceBetween: 20,
                            slidesPerView: 3,
                        }
                    }} 
                        modules={[Pagination, A11y, Autoplay]}
                        spaceBetween={25}
                        slidesPerView={9}
                        pagination={{ clickable: true }}
                        loop={true}
                        autoplay={false}
                        centeredSlides={true}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner4.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner1.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner5.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner4.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner1.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner2.png" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="partnerLogo">
                                <img src="/assets/images/partner3.png" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section> */}
            <section className="venueSpotSec">
                <div className="container">
                    <h2 className="secTitle">Venue Spotlight</h2>
                    <div className="venueSpot-inner">

                        <ImageGallery items={images}
                            autoPlay={true}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            infinite={true} />


                        {/* <img src="/assets/images/venueSpotImg.png" /> */}
                        <div className="venueSpotContent">
                            <h2><span className="textUpper">House of Lalpur</span> </h2>
                            <p>
                            Discover the House of Lalpur, an idyllic 50-acre campus dedicated to leadership and adventure training. Nestled amidst the stunning landscapes of the Shivalik and Himalayan ranges, just 5 kilometers off the Manali Highway, the House of Lalpur provides an ideal setting for immersive learning and personal growth.
                                <br/><br/>
The campus offers a serene environment away from the distractions of modern life, allowing students to fully engage with the program. With state-of-the-art facilities, including interactive knowledge sessions, adventure sports, and cultural immersion activities, the House of Lalpur stands as a testament to the commitment of the MU20 School of Leadership.
                                <br/><br/>
Join us at the House of Lalpur and experience a transformative journey where nature and learning converge, empowering you to become a confident and capable leader.

                            </p>
                        </div>
                    </div>
                </div>
            </section>






            <section className=" testimonialSec testimonialHomeSec">
                <div className="container-full">
                    
                    <h2 className="secTitle">Testimonials</h2>
                    

                    <Swiper
              breakpoints={{
                1440: {
                  spaceBetween: 40,
                  slidesPerView: 4,
                  centeredSlides: false,
                },
                768: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                },
                200: {
                  spaceBetween: 20,
                  slidesPerView: 1,
                  autoplay: false,

                },
              }}
              modules={[Autoplay, A11y]}
              spaceBetween={50}
              slidesPerView={5}
              loop={true}
              slidesOffsetBefore={200}
        //       autoplay={{
        //      delay: 1500,
        //      disableOnInteraction: false,  
        //      pauseOnMouseEnter: true
        //    }}

              centeredSlides={true}
          >
            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/YLPwebtest1.mp4" type="video/mp4" />
                </video>

                

              

                {/* <img src="assets/images/testimonial1.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />

                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            
        <SwiperSlide>

                <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/YLPwebtest2.mp4" type="video/mp4" />
                </video>

                    

                  <div className="textWrapper">
                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                    <h6 className="titleWatch">Watch Story</h6>
                  </div>
                </div>

        </SwiperSlide>       
             <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/YLPWebtest3.mp4" type="video/mp4" />
                </video>

               

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

         
        
          </Swiper>
                </div>
            </section>
                <section className="reviewSec">
                <div className="container-full">

                    <Swiper

                        // install Swiper modules
                        modules={[Autoplay, Navigation, A11y, EffectFade]}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 1500,
                            disableOnInteraction: true,
                            pauseOnMouseEnter: true
                          }}
                        centeredSlides={true}
                        effect="fade"
                        navigation={true}
                    //onSwiper={(swiper) => console.log(swiper)}
                    //onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/Bootcamp/1000+Studentsoncampus(1).jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">1,500+ Students on Campus</h3>
                                    <p className='pt-10 fs-14'>Over 1,500 students have experienced the transformative environment of our residential bootcamps.</p>
                                    {/* <p>Students Engaged</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/Bootcamps/70+cohorts(2).jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">70+ Cohorts Formed</h3>
                                    <p className='pt-10 fs-14'>Successfully organized more than 70 cohorts, fostering close-knit learning communities.</p>
                                    {/* <p> Nationalities Represented</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/Bootcamp/50+schools_participated.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">50+ Schools Participated</h3>
                                    <p className='fs-14 pt-10'>Participation from over 50 schools, bringing diverse perspectives and enriching the learning experience.
                                    </p>
                                    {/* <p> Conferences Held</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/Bootcamp/100+projects_submitted.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">100+ Projects Submitted </h3>
                                    <p className='fs-14 pt-10'>Students have submitted over 100 projects, demonstrating their creativity and innovation.
                                    </p>
                                    {/* <p> Academic Mentors</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/Bootcamp/15+events(1).jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">15+ Events and Activities</h3>
                                    <p className='fs-14 pt-10'>Hosted more than 15 events and activities, offering a holistic and engaging bootcamp experience.
                                    </p>
                                    {/* <p>Operates in 7 Countries</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img src="/assets/images/Bootcamp/250+hoursofworkshops.jpg" alt='home-img' />
                                <div className="reviewContent">
                                    <h3 className="reviewTitle">250+ Hours of Workshops

                                    </h3>
                                    <p className='fs-14 pt-10'>Conducted more than 300 hours of intensive workshops covering various topics.
                                    </p>
                                    {/* <p>Operates in 7 Countries</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>




                    {/* <img src="/assets/images/reviewImg.png" alt='home-img' />
                    <div className="reviewContent">
                        <h3 className="reviewTitle">Last year Summit</h3>
                        <p>OxfordMUN India is more than a conference; it's a movement towards nurturing informed</p>
                    </div> */}
                </div>
            </section>
           <section className="joinSec">
                <div className="container">
                    <div className="row">
                        <div className="column logoImage">
                            <img src="/assets/images/pillarimage/students_on_campus_(2).jpg" alt='home-img' />
                        </div>
                        <div className="column content-wrapperCol">
                            <div className="content-wrapper">
                                <h5 className="contentTitle">Join the movement </h5>
                                <p>Step into a world of possibilities with the Young Leader's Bootcamp (YLB). Join a vibrant community of young, passionate individuals dedicated to becoming the leaders of tomorrow. At YLB, you'll participate in hands-on workshops, innovative learning experiences, and collaborative projects that challenge and inspire you to grow.</p>
                                <a href="https://musite.b-cdn.net/videos/MU20%20Leadership%20and%20Adventure%20Bootcamp.pdf" class="registerBtn" target="_blank"> Download Brochure </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="callToAction">
                <div className="container  ">
                    <div className="  callToAction-inner">
                        <div className="row">
                            <div className="column callToActionLeft">
                                <h5>Ready to make a <br /> difference: </h5>
                                <p>Register Your School for Young Leaders’ Bootcamp Today!</p>
                            </div>
                            <div className="column callToActionRight">
                                <p>Be a part of the Young Leader's Bootcamp Today! 
Be part of a network that supports and motivates you to reach new heights, develop your unique leadership style, and make a meaningful impact in your community and beyond. 
<br />
Are you ready to transform your potential into action? Join the movement and embark on your leadership journey with us today. Reach out to us for comprehensive details about the program, how to apply, and any other questions you have.

                                </p>

                                <button className="registerBtn"  onClick={togglePopup} >
                                Register Now
                            </button>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}

                                {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> Register Now </a> */}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="faqSec">

                <div className="container row faqInner">
                    <div className="faqInnerLeft">
                        <div className="faqInnerLeft-inner">
                            <img src="/assets/images/whitelogo@4x.png" alt="home-img" />
                            <p className="fs-18">Shape the Future with Us: Join the Young Leaders’ Bootcamp Today!
                            </p>
                            <p className="fs-18">Join the Young Leaders’ Bootcamp (YLB) and take the first step towards shaping the future. YLB is more than just a bootcamp—it's a dynamic platform designed to nurture your leadership potential and empower you to drive change. Whether you're leading initiatives, collaborating with peers, or overcoming challenges, YLB offers the perfect environment to develop your skills and make a meaningful impact. Step into a world of opportunity where your actions can inspire others and create a brighter future. Together, let's shape the world we want to see.</p>
                            {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn " target="_blank"> Register Now </a> */}

                            <button className="registerBtn"  onClick={togglePopup} >
                            Register Now 
                            </button>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}

                            <p className="fs-18 p-0">Still Have a question?  No worries.</p>

                            <div className="contactDetail">
                                <a href="mailto:madhur.oza@mu20.co " ><img src="/assets/images/email.svg" alt="home-img" />madhur.oza@mu20.co </a>
                                <a href="tel:7987519367" ><img src="/assets/images/whatsapp.svg" alt="home-img" />7987519367</a>
                            </div>
                            <div className="socialLinks">

                                <a href=" https://www.instagram.com/mu20.world?igsh=MThkY3NkeGJ3bHNtaA==" target="_blank">
                                    <img src="/assets/images/insta.svg" alt="home-img" /></a>
                                <a href="https://www.linkedin.com/company/mu20/" target="_blank">
                                    <img src="/assets/images/linkdine.svg" alt="home-img" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="faqInnerRight">
                        <h2 className="secTitle">FAQs</h2>
                        <div class="faqBlock">
                            <Accordion allowZeroExpanded>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            What is the MU20 School Of Opportunity Young Leaders' Bootcamp?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="faqContent">A hands-on program for kids aged 10-14 to develop leadership skills through fun activities and workshops.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        Where is the bootcamp held?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>At the House of Lalpur, a 50-acre campus in Punjab’s Sutlej valley.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        Who can participate?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Children aged 10-14 interested in learning leadership and making new friends.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        What activities are included?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Activities include team-building, leadership workshops, kayaking, trekking, and more.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        What leadership skills will be developed?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Skills in communication, decision-making, problem-solving, empathy, and self-awareness.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        Who leads the bootcamp?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Experienced educators and professionals, including Dr. Sumer Singh.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        How long is the bootcamp?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>A four-day immersive experience from morning till evening.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        What does the fee cover?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Accommodation, meals, activities, sessions, and a program kit.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                            </Accordion>

                        </div>
                    </div>
                </div>
            </section>

        
        </div>    
                       {/* <Button type="primary" onClick={showModal}>
                 Open Modal
                </Button> */}
            {/* <RegisterModal /> */}
            {/* <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Modal> */}
            <Footer /> 
        </>
    )
}

export default Young_Leaders_Bootcamp;
