import React, {  useEffect, useRef, useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Helmet } from 'react-helmet';

import Popup from 'reactjs-popup';
import PopupForm from './PopupForm'; // Adjust the path as per your project structure

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";

function Residential_program() {

  const scrollToSection = () => {
    const section = document.getElementById('programs_s');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };



    const [showIframeLeft, setShowIframeLeft] = useState(false);
    const [showIframeRight, setShowIframeRight] = useState(false);

    const handleIconClickLeft = (e) => {
        e.preventDefault();
        setShowIframeLeft(true);
      };
    
      const handleIconClickRight = (e) => {
        e.preventDefault();
        setShowIframeRight(true);
      };
    
      const handleIframeCloseLeft = () => {
        setShowIframeLeft(false);
      };
    
      const handleIframeCloseRight = () => {
        setShowIframeRight(false);
      };






    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };


    // const handleMouseEnter = (event) => {
    //     event.target.play();
    //   };
    
    //   const handleMouseLeave = (event) => {
    //     event.target.pause();
    //   };
 
    const [isMuted, setIsMuted] = useState(true);
    const [isActive, setActive] = useState("false");

    const ToggleClass = () => {
        setActive(!isActive);
    };


     //Video on Hover Start
  

  const [showIframe, setShowIframe] = useState(false);
  const [playingVideos, setPlayingVideos] = useState(new Set());
  
  // Replace with actual logic to determine if the device is mobile or touch
  // Use window.matchMedia to determine if the viewport is mobile-sized
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  useEffect(() => {
    if (isMobile) {
      const videos = document.querySelectorAll('.video');
      const observerOptions = {
        root: null, // Use the viewport as the container
        rootMargin: '0px',
        threshold: 0.5, // Adjust this as needed
      };

      const handleIntersection = (entries) => {
        entries.forEach(entry => {
          const video = entry.target;
          if (entry.isIntersecting) {
            if (video.paused) {
              // Attempt to play the video and handle cases where play() might not return a Promise
              const playPromise = video.play();
              if (playPromise !== undefined) {
                playPromise.catch(error => console.error('Play interrupted:', error));
              }
              setPlayingVideos(prev => new Set(prev.add(video)));
            }
          } else {
            if (!video.paused) {
              // Attempt to pause the video and handle cases where pause() might not return a Promise
              const pausePromise = video.pause();
              if (pausePromise !== undefined) {
                pausePromise.catch(error => console.error('Pause interrupted:', error));
              }
              setPlayingVideos(prev => {
                const updated = new Set(prev);
                updated.delete(video);
                return updated;
              });
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, observerOptions);
      videos.forEach(video => {
        observer.observe(video);
      });

      return () => {
        videos.forEach(video => {
          observer.unobserve(video);
        });
      };
    }
  }, [isMobile, playingVideos]);

  const handleMouseEnter = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (video.paused) {
        // Attempt to play the video and handle cases where play() might not return a Promise

        video.muted = false; // Unmute the video
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => console.error('Play interrupted:', error));
        }
      }
    }
  };

  const handleMouseLeave = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (!video.paused) {
        // Attempt to pause the video and handle cases where pause() might not return a Promise
        const pausePromise = video.pause();
        if (pausePromise !== undefined) {
          pausePromise.catch(error => console.error('Pause interrupted:', error));
        }
        video.muted = true; // Mute the video
      }
    }
  };




  //Video on Hover End
    return (
        <>

<Helmet>
        <title>MU20 Residential Programs       </title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
            <Header />
            <section className="oxfordBanner">
                {/* <div style="padding-bottom: 56.25%; max-width: 100%; position: relative;"><iframe
                    src="https://player.vimeo.com/video/347119375?autoplay=1&loop=1&muted=1&title=0&portrait=0&byline=0&keyboard=0"
                    width="800px" height="450px" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;"
                    frameborder="0"></iframe></div>   */}

                <div>
                    <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false} playsinline>
                        {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
                        <source src="https://musite.b-cdn.net/videos/pathways_main_page(1).mp4" type="video/mp4" />
                    </video>
                </div>


                <div className="banner-content">
                     <div className='page_Heading'>
                        <h1>Residential Programs</h1>
                        <p className='heading_cont'>Immerse yourself in our transformative residential programs. Live, learn, and grow in a supportive community, while developing leadership skills and lifelong friendships.</p>  
                    </div>
                    <div className={isActive ? "mute" : "unmute"}>
                        <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src=" /assets/images/mute.svg" alt="unMuteIcon" />
                            <img className="muteIcon" src="/assets/images/unmute.svg" alt="MuteIcon" /></a>
                    </div>
                </div>
            </section>

            <section className="boderGradient bgBlack p-80-20 pillarBoxSec">
                <div className="container pillarBox-outer">
                    <div className="pillarBox-innerLeft">
                        <div className="pillarBox ">
                            <div className='w-50'>
                            <img className="leftImg" src="assets/images/pillarimage/strengthen.jpg" alt="pillarImg" />
                            </div>
                           
                            <div className="pillarBoxContent w-50">
                                <h4 className="fs-34 fw-600 pb-20 textOrg ">Strengthen
                                </h4>
                                <p className="fs-16 fw-400 textWhite">Our residential programs are designed to strengthen the skills and abilities of each participant. Through intensive, hands-on activities and comprehensive workshops, students gain in-depth knowledge and practical experience. These programs focus on building resilience, enhancing critical thinking, and developing leadership qualities, ensuring that every participant leaves stronger and more capable.
                                </p>
                            </div>
                        </div>
                        <div className="pillarBox Support m-0">
                            <div className="pillarBoxContent w-50">
                                <h4 className="fs-34 fw-600 pb-20 textOrg ">Support

                                </h4>
                                <p className="fs-16 fw-400 textWhite">Support is a fundamental aspect of our residential programs. We provide a nurturing environment where participants can thrive. With access to experienced mentors, dedicated staff, and a supportive peer network, students receive the guidance and encouragement they need to overcome challenges and achieve their goals. Our commitment to support ensures that every participant feels valued and empowered.
                                </p>
                            </div>
                            <div className='w-50'>
                            <img className="rightImg" src="assets/images/pillarimage/support.jpg" alt="pillarImg" />
                            </div>
                           
                        </div>
                    </div>
                    <div className="pillarBox-innerRight">
                        <div className="pillarBox m-0">

                        <div>
                          <img className="topImg" src="assets/images/pillarimage/sustain.jpg" alt="pillarImg" />
                          </div>


                            
                            <div className="pillarBoxContent">
                                <h4 className="fs-34 fw-600 pb-20 textOrg ">Sustain



                                </h4>
                                <p className="fs-16 fw-400 textWhite">Sustainability is integral to our residential programs. We aim to instill lasting values and skills that participants can carry forward into their future endeavors. Through sustainable practices, long-term projects, and a focus on continuous improvement, we ensure that the impact of our programs extends beyond the duration of the stay. Our goal is to equip students with the tools and mindset needed to sustain their growth and contributions to society.


                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="programsSec bglightBlack  p-80-20" id='programs_s'>
                <div className="container">
                    <h2 className="fs-80 fw-600 textWhite">Programs</h2>
                    <div className="program-outer">
                    <div className="program-innerLeft">
                                <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                          <source src="https://musite.b-cdn.net/videos/pathwaysm_Main_Page.mp4" type="video/mp4" />
                        </video>
                        </div>
                        <div className="program-innerRight">
                            <h3 className="fs-34 fw-600 pb-20 textOrg ">MU20 School of Opportunity Residential Program, Pathways World School
                            </h3>
                            <p className="fs-16 fw-400 textWhite pb-20">The MU20 Residential Program, held at Pathways World School, Gurugram is a cornerstone of our residential offerings, presenting students with exhilarating opportunities to discover their passions and learn to be leaders. Over the course of this program, students engage in a diverse array of activities, from workshops and seminars to field trips and team-building exercises. They are encouraged to explore their interests, develop new skills, and build lasting relationships with peers and mentors. Through this journey, participants emerge as confident, capable leaders ready to make a positive impact on their communities and beyond.
                            </p>
                            <h4 className="fs-18 fw-400 textWhite"><span className="textOrg">Venue</span>Pathways World  School</h4>
                            <ul className="progarmDetailList">
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Location.svg" alt="Location" />
                                        Location:<span className="fs-18 fw-400"></span>Gurugram</h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Certification.svg" alt="Certification" />
                                        Certification:<span className="fs-18 fw-400">Yes</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Duration.svg" alt="Duration" />
                                        Duration:<span className="fs-18 fw-400"> </span>3 days</h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Reidential.svg" alt="Reidential" />
                                        Cohort size:<span className="fs-18 fw-400">400+</span></h6>
                                </li>
                            </ul>
                            {/* <a className="registerBtn" href='/Residential_program'>
                           Explore <img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                            </a>
                            <button className="registerBtn joinbtn"  onClick={togglePopup} >
                            Join now 
                            </button> */}

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}
                            {/* <a href="#" className="registerBtn">Join now<img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            {/* <a href="#" className="readMoreBtn">Read More</a> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="bgBlack testimonialSec testimonialHomeSec">
        <div className="container-full">
          <div className="topHeading">
            {/* <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">clients</h6> */}
            <h2 className="fs-80 fw-600 textWhite">Testimonials</h2>
          </div>

          <Swiper
              breakpoints={{
                1440: {
                  spaceBetween: 40,
                  slidesPerView: 4,
                  centeredSlides: false,
                },
                768: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                },
                200: {
                  spaceBetween: 20,
                  slidesPerView: 1,
                },
              }}
              modules={[Autoplay, A11y]}
              spaceBetween={50}
              slidesPerView={5}
              loop={true}
              autoplay={{
             delay: 1500,
             disableOnInteraction: false,  
             pauseOnMouseEnter: true
           }}
              centeredSlides={true}
          >
             <SwiperSlide>

<div className="testimonialImg">
<video className="testimonialVideo video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline controls>
<source src="https://musite.b-cdn.net/LABwebtest1.mp4" type="video/mp4" />

</video>

  <div className="textWrapper">
    <img src="/assets/images/videoSec.svg" alt='home-img' />
    <h6 className="titleWatch">Watch Story</h6>
  </div>
</div>

</SwiperSlide>

   
        <SwiperSlide>

                <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/LABwebtest2.mp4" type="video/mp4" />
                
                </video>

                  <div className="textWrapper">
                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                    <h6 className="titleWatch">Watch Story</h6>
                  </div>
                </div>

        </SwiperSlide>



            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/LABwebtest3.mp4" type="video/mp4" />
                 
                </video>

               

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>

<div className="testimonialImg">
<video className="testimonialVideo video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline controls>
<source src="https://musite.b-cdn.net/YLPwebtest1.mp4" type="video/mp4" />

</video>

  <div className="textWrapper">
    <img src="/assets/images/videoSec.svg" alt='home-img' />
    <h6 className="titleWatch">Watch Story</h6>
  </div>
</div>

</SwiperSlide>

    <source src="https://musite.b-cdn.net/YLPwebtest2.mp4" type="video/mp4" />
       


            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/YLPwebtest2.mp4" type="video/mp4" />
                 
                </video>

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/YLPWebtest3.mp4" type="video/mp4" />
                 
                </video>

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/videos/summit_web_test.mp4" type="video/mp4" />
                 
                </video>

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

          </Swiper>
        </div>
      </section>

      <section className="p-80-20 bglightBlack networkSchoolSec">
        <div className="container">
          <div className="topHeading">
            <h2 className="fs-80 fw-600 textWhite">Participating Schools</h2>
           
          </div>
          <div className="networkSchoolSlider-outer">
            <Swiper
              modules={[Navigation, Autoplay, A11y]}
              spaceBetween={30}
              slidesPerView={'auto'}
               cssMode={true}              
              centerInsufficientSlides={false}
              navigation={false}
              loop={true}            
              autoplay={{
                delay: 1000,  // Set delay to 0 to start autoplay immediately
                disableOnInteraction: true,  // Disable autoplay when user interacts with slides
                pauseOnMouseEnter: true  // Pause autoplay on mouse enter
              }}
              freeMode={true} 
              centeredSlides={false}
            >
              

              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/always.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/ashoka.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bharati.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bihani.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/billabong.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/birla.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bk_birla.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/crossroads.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/dalimess.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/delhi.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/delhiworld.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/dolphin.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/doon.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/doon_girl.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/duty.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/emrald.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/goenka.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/golden.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/him.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/ies.png" alt="networkSch" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/inventure.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jayshree.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jbcn.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jindal.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jodhamal.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jyoti.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/kasiga.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/kunska.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/macro.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/mann.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/mcd.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/no.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pacific.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pathways.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pinegrove.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/queen.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajkumar.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajkumar_college.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajmas.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajmata.png" alt="networkSch" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rockwoods.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/sagar_school.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/sanskar.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/satpuda.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/satyasai.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/school.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/scindia.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/shishukunj.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/singapoor.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/strength.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/timor.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/truth.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/uwc.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/valistus.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vantage.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vidhya.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/virtus.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vivek.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/wisdom.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/witty.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/world.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/zabar.png" alt="networkSch" />
                </div>
              </SwiperSlide>




            </Swiper>
          </div>
        </div>
      </section>

            <section className=" bglightBlack  p-80-20 opportunitiesSec">
                <div className="container opportunities-outer">
                    <div className="opportunities-inner">
                        <div className="opportunities-wrapper purpleGradient">
                            <img src="assets/images/residential_grid/1.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite pb-10">500+ Participants</h6>
                                <p className="fs-16 fw-400 textWhite pb-20">Over 1,000 students have engaged in our immersive residential programs.</p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                        <div className="opportunities-wrapper yellowGradient">
                            <img src="assets/images/residential_grid/2.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite  pb-10">10+ Exciting Challenges
                                    </h6>
                                <p className="fs-16 fw-400 textWhite pb-20"> Featuring more than 10 diverse and stimulating challenges to push boundaries and inspire growth.</p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                    </div>
                    <div className="opportunities-inner">
                        <div className="opportunities-wrapper orgGradient">
                            <img src="assets/images/residential_grid/3.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite pb-10">50+ Schools Represented
                                </h6>
                                <p className="fs-16 fw-400 textWhite pb-20"> Participation from over 100 schools, ensuring a rich and diverse learning environment.</p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                        <div className="opportunities-wrapper blueGradient">
                            <img src="assets/images/residential_grid/4.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite  pb-10">10+ Industry Partners</h6>
                                <p className="fs-16 fw-400 textWhite pb-20"> Collaborating with over 10 industry partners to provide real-world insights and opportunities.
                                </p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                    </div>
                    <div className="opportunities-inner">
                        <div className="opportunities-wrapper redGradient">
                            <img src="assets/images/residential_grid/5.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite pb-10">200+ Hours of Training
                                </h6>
                                <p className="fs-16 fw-400 textWhite pb-20"> Delivering over 200 hours of comprehensive training sessions tailored to student needs.

</p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                        <div className="opportunities-wrapper greenGradient">
                            <img src="assets/images/residential_grid/6.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite  pb-10">20+ Workshops</h6>
                                <p className="fs-16 fw-400 textWhite pb-20">Hosting more than 25 workshops to enhance skills and knowledge across multiple disciplines.
                                </p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="callToAction bgBlack callToActionPillar ">
                <div className="container  ">
                    <div className="  callToAction-inner">
                        <div className="row">
                            <div className="column callToActionLeft">
                                <h5>Join us for a <br />Great experience</h5>
                                {/* <p>Register your school today to the Oxford MUN India 2024.</p> */}
                            </div>
                            <div className="column callToActionRight">
                                <p>
                                    Enroll in our Residential Programs to live, learn, and grow in a supportive community. Visit the link below to secure your spot and experience an unparalleled journey of personal and academic development.
                                </p>

                                <button className="registerBtn" onClick={scrollToSection}>
  Explore Now
  {/* <img className="widthAuto" src="assets/images/pillarPage/ArrowOrg.svg" alt="iconBtn" /> */}
</button>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}
                                {/* <a href="#" className="registerBtn">Join now<img className="widthAuto"
                                    src="assets/images/pillarPage/ArrowOrg.svg" alt="iconBtn" /></a> */}
                                {/* <a href="#" className="readMoreBtn">Read More</a> */}
                                {/* <a href="#" className="registerBtn"> Register Now </a>  */}
                            </div>
                        </div>
                    </div>

                </div>
            </section>






            <section className="programsSec bgBlack  p-80-20   pt-0">

            <div className="topHeading">
            <h2 className="fs-80 fw-600 textWhite">Residential Progam Aftermovie</h2>
          </div>

                <div className="container past_program_container">
                <div className="program-outer">
                    
              



                      <div className="program-innerRight" style={{ position: 'relative' }}>
                  <video
                    className="sliderBgImage past_movie_vid"
                    width="600"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                    onClick={handleIconClickRight}
                  >
                    <source src="https://musite.b-cdn.net/videos/pathwaysm_Main_Page.mp4" type="video/mp4" />
                  </video>


                  <div className="video-caption"
                   style={{ backgroundColor: "white" }}>
                    <p>MU20 Pathways Residential Programme 2024 | Pathways World School | Aftermovie</p>
                    <a href="#" className="past_movie" onClick={handleIconClickRight}>
                      <img className="arrow" src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                    </a>
                  </div>
                  {showIframeRight && (
                    <div className="iframe-container_right  iframe-container responsive-iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/rnEspqnT68k?si=8lc7OCU1TLNeCA1I&autoplay=1"
                        width="650"
                        height="400"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="video"
                      ></iframe>
                      <button onClick={handleIframeCloseRight} className="close-button">Close</button>
                    </div>
                  )}
                </div>
                </div>
                </div>
                
             </section>


        

            <Footer />
            
        </>
    )
}

export default Residential_program