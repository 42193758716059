import React, { useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios'
import PopupComponent from 'reactjs-popup'; // Rename the import to PopupComponent
import PopupForm from './PopupForm'; // Adjust the path as per your project structure
import { Helmet } from 'react-helmet';


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";

function CrafttheFutureChallenge24 () {



    

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };



    const [isMuted, setIsMuted] = useState(true);
    const [isActive, setActive] = useState("false");
    const [payload,setPayload] = useState({
        person_name:"",
        email:"",
        enquiry:"",
        school_name:"",
        school_location:"",
        contact_number:"",
        message:""
    })

    const handleChange = (e) =>{
        let {value,name}= e.target
        let data=payload
        data={...data,[name]:value}
        setPayload(data)
    }


 






    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
          const response = await axios.post('https://server.mu20.co/user', payload);
          console.log(response.data);
          
        } 
        catch (error) {
          console.log('An error occurred:', error);
          
        }
      };
      



    
    const handleSelect = (e)=>{
        let {value}= e.target
        let data=payload
        data={...data,'enquiry':value}
        setPayload(data)

    }

    const ToggleClass = () => {
        setActive(!isActive);
    };
    return (
        <>
     <Helmet>
        <title>Craft the Future Challenge         </title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
            <Header />
            <div className='orange_theme'>
            {/* <!-- banner section --> */}
            <section className="oxfordBanner">
                <div>
                    <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false}>
                        {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
                        <source src="https://musite.b-cdn.net/ctfmainpage.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="banner-content">
                    <h1>Craft the Future Challenge‘24 </h1>
                    <div className={isActive ? "mute" : "unmute"}>
                        <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/mute.svg" alt="unMuteIcon" />
                            <img className="muteIcon" src="/assets/images/unmute.svg" alt="MuteIcon" /></a>
                    </div>
                </div>
            </section >
          
            
            {/* <!-- introduction --> */}
            < section className="intro-section communityIntro-section " >
                <div className="container">
                    <div className="introduction">
                        <h2 className="secTitle textBlack">Introduction</h2>
                        <p>Step into the future with the Craft the Future Challenge, an exciting competition inviting creative minds from grades 9 and 12 to design a futuristic kitchen that leverages AI for enhanced functionality and interaction. This challenge is all about reimagining the future of the kitchen, integrating AI to transform it into a smart, interactive space. Whether it’s automated meal planning, waste reduction, or other AI-driven innovations, we’re looking for visionary ideas that can shape tomorrow’s kitchens.</p>
                    </div>
                </div>
            </section >
            {/* <!-- Why OxfordMUN India ? --> */}
            < section className="whyOxfordSec communityWhyOxfordSec" >
                <div className="container">
                    <div className="oxford-mun">
                        <h2 className="secTitle ">Why Join the Craft the Future Challenge?</h2>
                    </div>
                    <div className="inner-box">
                        <div className="row">
                            <div className="column">
                                <div className="oxford-box">
                                    <h3 className="textLOrg">Innovative Design</h3>
                                    <p className="textLOrg">Showcase your creativity by designing a kitchen that seamlessly incorporates AI, making everyday tasks more efficient and enjoyable. This is your chance to push the boundaries of design and technology, creating a smart kitchen concept that could revolutionize how we interact with our homes. Let your imagination run wild and present a vision of the future that combines functionality with innovation.</p>
                                </div>
                                <div className="oxford-box">
                                    <h3 className="textLOrg">Skill Development</h3>
                                    <p className="textLOrg">Enhance your design, engineering, and problem-solving skills through a practical, hands-on project. This challenge provides a unique opportunity to apply theoretical knowledge to real-world scenarios, helping you to refine your technical abilities and creative thinking. Gain invaluable experience that will set you apart in your academic and professional journey.</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="oxford-box">
                                    <h3 className="textLOrg">Expert Guidance</h3>
                                    <p className="textLOrg">Benefit from mentorship by leading industry experts who will provide insights and feedback on your designs. Learn from the best in the field, gaining access to their wealth of knowledge and experience. This mentorship will not only improve your project but also offer you a glimpse into the industry, preparing you for future endeavors in design and technology.</p>
                                </div>
                                <div className="oxford-box">
                                    <h3 className="textLOrg">Prestigious Recognition</h3>
                                    <p className="textLOrg">Gain recognition from esteemed judges and potentially earn scholarships and other exciting prizes. Winning this challenge can open doors to prestigious educational opportunities and enhance your resume. Stand out among your peers and gain the accolades that come with being a pioneer in innovative design.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* <!-- Conference Details --> */}
            < section className="conference communityConference" >
                <div className="container">
                    <div className="confere-detail">
                        <h2 className="secTitle textBlack">CHALLENGE DETAILS</h2>

                        <div className="row">
                            <div className="column">
                                <div className="confere-box">
                                    <h3 className="textBlack">Who</h3>
                                    <p>Students studying in classes </p><span className="bgColorText">9th to 12th</span>

                                </div>
                                <div className="confere-box m-0">
                                    <h3 className="textBlack">When and Where</h3>
                                       

                                    <p> The challenge took place from<span className="bgColorText"> 5th-12th August, 2024 </span> through an online platform.
                                    </p>
                                </div>
                            </div>
                            <div className="column confere-reg orgBg">
                                <div className="confere-regInner">
                                    <h3>Registration</h3>
                                    <p>To express your interest in joining, schools are
                                        requested to register by <span>30th April 2024.</span> Please note that the priority
                                        registrations for students will end on <span>29th June</span> , and final registration
                                        deadlines will be <span>16th July 2024.</span>
                                    </p>
                                    {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> Register Now </a> */}
                                    <button className="registerBtn"  onClick={togglePopup} >
                                    Register Now
                            </button>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="communityFlameUni p-80-20">
                <div className="container">
                    <div className="communityFlameUni-outer">
                        <div className="communityFlameUni-innerLeft">
                            <img className="widthAuto" src="/assets/images/FlamesUnLogo.png" alt="FlamesUnLogo" />
                            <h3 className="textLOrg fw-600 fs-42 mb-10 ">FLAME University</h3>
                            <p className="fs-18 fw-400 textBlack">
                                FLAME University, the pioneer of liberal education in India, offers an unparalleled interdisciplinary educational experience. Situated on a picturesque 70-acre campus in Pune, FLAME University is home to five distinctive schools—Liberal Education, Business, Communication, Computing and Data Science, and Design, Art & Performance. With a commitment to fostering critical thinking, creativity, and leadership, Recognized by the University Grants Commission (UGC), FLAME is dedicated to societal contribution and innovative education, preparing students to excel academically and drive positive change globally. This partnership underscores FLAME’s dedication to nurturing future innovators and leaders through real-world challenges and experiential learning.
                            </p>
                        </div>
                        <div className="communityFlameUni-innerRight">
                            <img className="universityImg" src="/assets/images/Flames1.png" alt="FlamesUniversity" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="experienceSec p-80-20 ">
                <div className="container-full">
                    <h2 className="secTitle textBlack">Things you experience</h2>
                    <Swiper breakpoints={{
    1440: {
        spaceBetween: 20,
        slidesPerView: 4,
        centeredSlides: true,
    },
    768: {
        spaceBetween: 20,
        slidesPerView: 3,
        centeredSlides: false,
    },
    200: { // This covers mobile devices
        spaceBetween: 20,
        slidesPerView: 1,
        centeredSlides: true, // Disable centeredSlides for better view on mobile
    }
}}
    modules={[Pagination, A11y, Autoplay]}
    spaceBetween={20}
    slidesPerView={5}
    pagination={{ clickable: true }}
    loop={true}
    autoplay={{
        delay: 1500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
    }}
    slidesOffsetBefore={100}
    centeredSlides={true}
>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Collaborative Projects</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_1.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_1.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Work closely with peers from diverse backgrounds on collaborative projects in the challenge. This teamwork enhances your problem-solving skills and allows you to learn different perspectives and approaches to design.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Expert Mentorship</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_2.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_2.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Gain valuable insights from leading industry experts through mentorship sessions. Receive personalized feedback and guidance on your designs, helping you refine your concepts and enhance their impact.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Real-World Applications</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_3.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_3.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Explore real-world applications of Artificial Intelligence. Understand how technology can transform everyday tasks and create smarter, more efficient living spaces.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Creative Freedom</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_4.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_4.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Enjoy the creative freedom to envision and design a futuristic kitchen. Let your imagination soar as you conceptualize innovative solutions that address practical challenges.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Presentation Skills</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_5.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_5.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Develop and hone your presentation skills by showcasing your designs to a panel of judges. Learn to effectively communicate your ideas and the impact of your innovative solutions.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Networking Opportunities</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_6.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_6.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Connect with like-minded students from all around the place and professionals in the field of design and technology. Build a network that can support and inspire you in your future academic and professional endeavors.</p>
                            </div>
                        </SwiperSlide>
                       
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Collaborative Projects</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_1.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_1.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Work closely with peers from diverse backgrounds on collaborative projects in the challenge. This teamwork enhances your problem-solving skills and allows you to learn different perspectives and approaches to design.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Expert Mentorship</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_2.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_2.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Gain valuable insights from leading industry experts through mentorship sessions. Receive personalized feedback and guidance on your designs, helping you refine your concepts and enhance their impact.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Real-World Applications</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_3.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_3.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Explore real-world applications of Artificial Intelligence. Understand how technology can transform everyday tasks and create smarter, more efficient living spaces.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Creative Freedom</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_4.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_4.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Enjoy the creative freedom to envision and design a futuristic kitchen. Let your imagination soar as you conceptualize innovative solutions that address practical challenges.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Presentation Skills</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_5.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_5.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Develop and hone your presentation skills by showcasing your designs to a panel of judges. Learn to effectively communicate your ideas and the impact of your innovative solutions.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="experienceBox">
                                <div className="experienceBox-inner pb-30 d-flex">
                                    <h5 className="fs-32 fw-600 textBlack">Networking Opportunities</h5>
                                    <img className="widthAuto nonActive" src="/assets/images/craftthe_future_challenge24_icons/active_6.svg" alt="handOnWork" />
                                    <img className="widthAuto active" src="/assets/images/craftthe_future_challenge24_icons/non_active_6.svg" alt="handOnWork" />
                                </div>
                                <p className="fs-16 fw-400 textBlack">Connect with like-minded students from all around the place and professionals in the field of design and technology. Build a network that can support and inspire you in your future academic and professional endeavors.</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section className="rewardSec p-80-20">
                <div className="container">
                    <h2 class="secTitle textBlack">Rewards</h2>
                    <div className="reward-outer">
                        <div className="rewardInner ">
                            <h5 className="textBlack fw-700 priceHeading">INR 50,000</h5>
                            <div className="innnerContent">
                                <div className="innnerContentTop">
                                    <h6 className="fw-600"> Grade 12 Level</h6>
                                    <p className="fs-18 fw-400 "> 50% Tuition Scholarship for B.Des</p>
                                </div>
                                <div className="innnerContentbotm">
                                    <h6 className="fw-600">Grade 11 Level</h6>
                                    <p className="fs-14 fw-400">100% Scholarship for FLAME Summer Immersion Program</p>
                                </div>
                            </div>
                            <h5 className="textBlack fw-700 priceTitle">2nd Prize</h5>
                        </div>

                        <div className="rewardInner rewardInnerCenter">
                            <h5 className="textBlack fw-700 priceHeading">INR 1,00,000</h5>
                            <div className="innnerContent">
                                <div className="innnerContentTop">
                                    <h6 className="fw-600">Grade 12 Level</h6>
                                    <p className="fs-18 fw-400 ">75% Tuition Scholarship for B.Des</p>
                                </div>
                                <div className="innnerContentbotm">
                                    <h6 className="fw-600">Grade 11 Level</h6>
                                    <p className="fs-14 fw-400">100% Scholarship for FLAME Summer Immersion Program + Flight Tickets
                                    </p>
                                </div>
                            </div>
                            <h5 className="textBlack fw-700 priceTitle">First Prize</h5>
                        </div>

                        <div className="rewardInner">
                            <h5 className="textBlack fw-700 priceHeading">INR 25,000</h5>
                            <div className="innnerContent">
                                <div className="innnerContentTop">
                                    <h6 className="fw-600">Grade 12 Level</h6>
                                    <p className="fs-18 fw-400 ">25% Tuition Scholarship for B.Des</p>
                                </div>
                                <div className="innnerContentbotm">
                                    <h6 className="fw-600">Grade 11 Level</h6>
                                    <p className="fs-14 fw-400">75% Scholarship for FLAME Summer Immersion Program</p>
                                </div>
                            </div>
                            <h5 className="textBlack fw-700 priceTitle">3rd Prize</h5>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="blackBarSec text-center">
                <div className="container">
                    <p className="fs-18 fw-400 textWhite">Top 100 students get 100% scholarship to their Summer Programmme on Data analytics, mentors from Google, and Meta</p>
                </div>
            </section>

            <section className="programsSec rewardSecondSec p-80-20">
                <div className="container">
               
                    <div className="program-outer">
                        <div className="program-innerLeft">
                            <img src="assets/images/venueSpotImg.png" alt="RewardsImg" />
                        </div>
                        <div className="program-innerRight">
                            <h3 className="fs-34 fw-600 pb-20 textBlack ">ABOUT THE CHALLENGE</h3>
                            <p className="fs-16 fw-400 textBlack pb-20">Step into the future with the Craft the Future Challenge, where students from grades 11 and 12 design a futuristic kitchen that leverages AI for enhanced functionality and interaction. This innovative competition is an opportunity to showcase your creativity and problem-solving skills while contributing to the future of smart living.</p>
                         
                            <ul className="progarmDetailList">
                                <li>
                                    <h6 className="fs-18 fw-600 textBlack"><img className="widthAuto"
                                        src="assets/images/pillarPage/LocationBlack.svg" alt="Location" />
                                        Platform<span className="fs-18 fw-400">Online </span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textBlack"><img className="widthAuto"
                                        src="assets/images/pillarPage/CertificationBlack.svg" alt="Certification" />
                                        Duration<span className="fs-18 fw-400">7 days</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textBlack"><img className="widthAuto"
                                        src="assets/images/pillarPage/DurationBlack.svg" alt="Duration" />
                                        Participants<span className="fs-18 fw-400"> 500+ students </span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textBlack"><img className="widthAuto"
                                        src="assets/images/pillarPage/ReidentialBlack.svg" alt="Reidential" />
                                        Certifications<span className="fs-18 fw-400">Will be provided<br/> upon completion</span></h6>
                                </li>
                            </ul>                            

                    <Popup
                        trigger={<button className="registerBtn registerChall">Register for the challange<img className="widthAuto"
                        src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></button>}
                        modal
                        nested
                    >
                        {close => (
                        <div className="modal">
                            <button className="close" onClick={close}>
                            &times;
                            </button>
                            <section className="bgBlack">
                                <div className="contactPopup bglightBlack">
                
                            <div className="contactForm">
                            <form onSubmit={handleSubmit}>
                                <label for="fname">Name</label>
                                <input type="text" id="fname" placeholder="Name" onChange={(e)=> handleChange (e) } name='person_name' />

                                <label for="cars">You are inquiring as a</label>
                                <select name="inquiring" id="inquiring" onChange={(e)=>handleSelect (e)}>
                                    <option value="inquiring">You are inquiring as a 2nd</option>
                                    <option value="inquiring">You are inquiring as a</option>
                                </select>


                                <label for="fname">School Name</label>
                                <input type="text" id="fname" placeholder="School Name (if you are a parent, kindly fill in your Ward's school name)" onChange={(e)=> handleChange (e) } name='school_name' />

                                <div className="loaction">
                                    <label for="location">School Location</label>
                                    <input type="tel" id="school-lacation" placeholder="School Location" onChange={(e)=> handleChange (e) }  name='school_location' />

                                    <label for="contact">Contact number</label>
                                    <input type="tel" id="contact-number" placeholder="Contact number" onChange={(e)=> handleChange (e) } name='contact_number' />
                                </div>

                                <label for="email">Email</label>
                                <input type="email" id="email" placeholder="Email" onChange={(e)=> handleChange (e) } name='email' />

                                <label for="message">Message</label>
                                <textarea type="textarea" placeholder="Add a message" onChange={(e)=> handleChange (e) } name='message'></textarea>

                                <button type="submit" onClick={handleSubmit}  className="formSubmit">Submit</button>
                            </form>
                        </div>
            </div>
        </section>        
        <div className="actions">
       
        </div>
      </div>
    )}
  </Popup>
                            
                        </div>
                    </div>
                </div>
            </section>  */}

            <section className="p-80-20 partnerSec communityPartnerSec">
                <div className="container-full">
                    <h2 className=" secTitle textBlack">Mentors from</h2>

                    <div className='mentors_logos'>
                            <div className="partnerLogo">
                                <img src="/assets/images/ericsion.png" />
                            </div>
                     
                            <div className="partnerLogo">
                                <img src="/assets/images/google.png" />
                            </div>

                            <div className="partnerLogo">
                                <img src="/assets/images/mckinsey.png" />
                            </div>
                     
                            <div className="partnerLogo">
                                <img src="/assets/images/tatadigital.png" />
                            </div>

                            
                      </div>




{/* <Swiper
  spaceBetween={15}
  slidesPerView={1}
  centeredSlides={true}
  loop={false}
  breakpoints={{
    768: {
      slidesPerView: 4,
      spaceBetween: 25,
    },
    480: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    320: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
  }}
>
  <SwiperSlide>
    <div className="partnerLogo">
      <img src="/assets/images/ericsion.png" alt="Ericsson" />
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className="partnerLogo">
      <img src="/assets/images/google.png" alt="Google" />
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className="partnerLogo">
      <img src="/assets/images/mckinsey.png" alt="McKinsey" />
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className="partnerLogo">
      <img src="/assets/images/tatadigital.png" alt="Tata Digital" />
    </div>
  </SwiperSlide>
</Swiper> */}


                </div>
            </section>

            <section className="callToAction pt-10 communityCallToAction">
                <div className="container  ">
                    <div className="  callToAction-inner">
                        <div className="row">
                            <div className="column callToActionLeft">
                                <h5>Ready to make a <br />difference?</h5>
                                <p>Register your school today to the Craft the Future Challenge.</p>
                            </div>
                            <div className="column callToActionRight">
                                <p>Join the Craft the Future Challenge and showcase your innovative ideas. Register now to embark on an unforgettable journey of creativity, collaboration, and cutting-edge design. 
                                    <br/><br/>Be a part of shaping the future with your visionary concepts!

                                </p>
                                <a href="https://bit.ly/CraftTheFutureChallengeBrochure" className="registerBtn" target="_blank">  Download Brochure </a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="faqSec communityFaqSec ">

                <div className="container row faqInner">
                    <div className="faqInnerLeft">
                        <div className="faqInnerLeft-inner">
                            <img src="/assets/images/whitelogo@4x.png" alt="home-img" />
                            <p className="fs-18">Create Tomorrow with Us: Join the Craft the Future Challenge Today!</p>
                            <p className="fs-18">Join the Craft the Future Challenge and turn your bold ideas into reality. This challenge isn’t just about competition—it’s about innovation and design that will influence the world of tomorrow. Whether you're driven by technology, creativity, or the desire to solve real-world problems, the Craft the Future Challenge provides the ideal platform to express your vision and make a tangible impact. Step into an environment where your imagination leads the way, and your solutions can shape a better future. Together, let's create the tomorrow we envision.</p>
                            {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn " target="_blank"> Register Now </a> */}

                            <button className="registerBtn"  onClick={togglePopup} >
                            Register Now
                            </button>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}

                            <p className="fs-18 p-0">Still Have a question?  No worries.</p>

                            <div className="contactDetail">
                                <a href="mailto:madhur.oza@mu20.co " ><img src="/assets/images/email.svg" alt="home-img" />madhur.oza@mu20.co</a>
                                <a href="tel:7987519367" ><img src="/assets/images/whatsapp.svg" alt="home-img" />7987519367</a>
                            </div>
                            <div className="socialLinks">

                                <a href=" https://www.instagram.com/mu20.world?igsh=MThkY3NkeGJ3bHNtaA==" target="_blank">
                                    <img src="/assets/images/insta.svg" alt="home-img" /></a>
                                <a href="https://www.linkedin.com/company/mu20/" target="_blank">
                                    <img src="/assets/images/linkdine.svg" alt="home-img" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="faqInnerRight">
                        <h2 className="secTitle textBlack">FAQs</h2>
                        <div class="faqBlock">
                            <Accordion allowZeroExpanded>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Who is eligible to participate in the Craft the Future Challenge?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="faqContent">High school students from grades 9 to 12 with an interest in technology, innovation, and design.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        Is the challenge conducted online or offline?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>The challenge is conducted entirely online, making it accessible to students everywhere.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        Is there a registration fee to enter the challenge?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>No, participation is completely free.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        What rewards can participants win?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Exciting rewards include cash prizes, scholarships, and unique benefits announced each year.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        What are the benefits of participating?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Participants gain mentorship from industry leaders, enhance their skills, and showcase their creativity, with the potential to win significant scholarships and prizes.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        Is the challenge limited to students with a background in design?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>No, the challenge is open to all students with innovative ideas, regardless of their design background.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        Do participants need to represent their school?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>No, students can enter individually and do not need to represent their school.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        What is the deadline for registration?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>The deadline is announced annually; check the official channels for details.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        When will the challenge take place?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>The schedule is announced each year, with the challenge typically held within a specific month.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                        What are the judging criteria?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Entries are judged on creativity, practicality, innovation, and potential impact in line with the year’s theme.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                            </Accordion>

                        </div>
                    </div>
                </div>
            </section>
            </div>
            

             <Footer /> 
        </>
    )
}

export default CrafttheFutureChallenge24

