import React, { useState, useRef } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';

import SimpleReactValidator from 'simple-react-validator';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";

function NetworkSchool() { 


 //Video on Hover Start

 const [shownIframe, setShownIframe] = useState(null);

 // Function to handle button clicks
 const handleVideoClick = (event) => {
   const iframeId = event.target.getAttribute('data-iframe-id');
   setShownIframe(iframeId);
   
   // Reset and set src to play the video
   const iframes = document.querySelectorAll('.video-iframe');
   iframes.forEach(iframe => {
     if (iframe.getAttribute('data-iframe-id') === iframeId) {
       const src = iframe.src;
       iframe.src = ''; // Reset the src
       iframe.src = src; // Set it again to start playback
     }
   });
 };



   const [showIframe, setShowIframe] = useState(false);
   
   const [playingVideos, setPlayingVideos] = useState(new Set());
   const [shownIframes, setShownIframes] = useState({});
 


    const handleMouseEnter = (event) => {
        event.target.play();
      };
    
      
      const handleMouseLeave = (event) => {
        event.target.pause();
      };
      const [isMuted, setIsMuted] = useState(true);
      const [isActive, setActive] = useState("false");
    
      const ToggleClass = () => {
        setActive(!isActive);
      };




    const [state, setState] = useState({
        title: '',
        email: '',
        review: ''
    });
    
    const validator = useRef(new SimpleReactValidator());

    const setTitle = (e) => {
        setState({ ...state, title: e.target.value });
    };

    const setEmail = (e) => {
        setState({ ...state, email: e.target.value });
    };

    const setReview = (e) => {
        setState({ ...state, review: e.target.value });
    };

    const submitForm = () => {
        if (validator.current.allValid()) {
            // Handle form submission
        } else {
            validator.current.showMessages();
            // Force update to show validation messages
            setState({ ...state });
        }
    };

    return (
        <>
            <Header />
            <section className="p-80-20 bgBlack network-school">
                <div className="container-full">
                    <div className="topHeading p-0">
                        <h6 className="fs-80 fw-600 textOrg pb-20">Network School</h6>
                        <p className="textWhite">The Oxford MUN, a beacon of diplomatic simulations worldwide, marks its groundbreaking entry into India at FLAME University, Pune, from 9th to 11th August 2024. Orchestrated by the MU20 School of Opportunity, this event is a fusion of Oxford's prestigious academic standards with the vibrant cultural diversity of India, offering an unparalleled MUN experience.
                        </p>
                    </div>
                </div>
            </section>

            <section className="p-80-20 bglightBlack school-logo boderGradient">
                <div className="container">
                    <div className="school-logo-outer">
                        <div className="">
                            <img src="assets/images/School_logos/always.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/ashoka.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/bharati.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/bihani.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/billabong.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/birla.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/delhi.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/bk_birla.png" alt="schoollogo" />
                        </div>


                        <div className="">
                            <img src="assets/images/School_logos/crossroads.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/dalimess.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/delhiworld.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/dolphin.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/doon.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/doon_girl.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/duty.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/emrald.png" alt="schoollogo" />
                        </div>

                        <div className="">
                            <img src="assets/images/School_logos/goenka.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/golden.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/him.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/ies.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/inventure.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/jayshree.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/jbcn.png" alt="schoollogo" />
                        </div>
                        <div className="">
                            <img src="assets/images/School_logos/jindal.png" alt="schoollogo" />
                        </div>
                    </div>
                </div>
            </section>










            <section className="p-80-20 bgBlack   pastProgVideoSec">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">Our Video</h6>
            <h2 className="fs-80 fw-600 textWhite">Past Program Videos</h2>
          </div>
        </div>

        <Swiper 
              // breakpoints={{
              //   1440: {
              //     spaceBetween: 30,
              //     slidesPerView: 4,
              //   },
              //   768: {
              //     spaceBetween: 30,
              //     slidesPerView: 2,
              //   },
              //   200: {
              //     spaceBetween: 15,
              //     slidesPerView: 1,
              //   }
              // }}




           modules={[Pagination, A11y, Autoplay]}
           spaceBetween={30}
           slidesPerView={5}
            speed={1000}
           slidesOffsetBefore={-200}
          //  pagination={{ clickable: true }}
           loop={true}  // Uncomment this line if you want the slides to loop
           freeMode={true}
           autoplay={{
             delay: 1500,
             disableOnInteraction: false,  
             pauseOnMouseEnter: true
           }}
           centeredSlides={true}
           initialSlide={3} 

            breakpoints={{
        // when window width is >= 200px
        200: {
          slidesPerView: 1,
          spaceBetween: 15,
          autoplay: false,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        // when window width is >= 1440px
        1440: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      }}
         
        >
      

          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">MU20 Summit Aftermovie</h4>
        <video
         data-iframe-id="1" 
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/summitpillarpage.mp4" type="video/mp4" />
        </video>
        {shownIframe === '1' && (
  <iframe
    className="video-iframe"
    data-iframe-id="1"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/yL1OyZ3-PYY?si=KYrxMvXOWBRxIcljs&autoplay=1" // Corrected URL
    title="YouTube video player 1"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 High School Summit | Asia's Biggest High School Leadership Conference | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>





          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Pathways Aftermovie</h4>
        <video
          data-iframe-id="2" 
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/pathwaysmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '2' && (
  <iframe
    className="video-iframe"
    data-iframe-id="2"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/rnEspqnT68k?si=6MdzW69ArLUdCPzl&autoplay=1" // Corrected URL
    title="YouTube video player 2"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 Pathways Residential Programme 2024 | Pathways World School | Aftermovie
          </p>
          <button className="readMoreIconBtn"   data-iframe-id="2" onClick={handleVideoClick} >
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>

          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">MGD LAB Aftermovie</h4>
        <video
          data-iframe-id="3" 
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '3' && (
  <iframe
    className="video-iframe"
    data-iframe-id="3"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/cjuBSu2Qtk0?si=nCdmzfaE9ASPHpdG&autoplay=1" // Corrected URL
    title="YouTube video player 3"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Maharani Gayatri Devi Girls' School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="3" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>

          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Doon LAB Aftermovie</h4>
        <video
          data-iframe-id="4" 
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '4' && (
  <iframe
    className="video-iframe"
    data-iframe-id="4"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/3ylVNxFkU6A?si=K_UhHRUW4TNZjUJG&autoplay=1" // Corrected URL
    title="YouTube video player 4"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          Leadership and Adventure Bootcamp | The Doon School, Dehradun Cohort | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="4" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>



          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">EHIS LAB Aftermovie</h4>
        <video
         data-iframe-id="5"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '5' && (
          <iframe
            className="video-iframe"
            data-iframe-id="5"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/MmFXW0yUxNw?si=vz7D1B3ThEQeUb2d&autoplay=1" // Added autoplay=1
            title="YouTube video player 5"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Emerald Heights International School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="5" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>



          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Mayo LAB Aftermovie</h4>
        <video
         data-iframe-id="6"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '6' && (
          <iframe
            className="video-iframe"
            data-iframe-id="6"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/NWeAlnmTfq8?si=nxq9bchKRPpL68IH&autoplay=1" // Added autoplay=1
            title="YouTube video player 6"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Mayo College Girls' School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="6" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
         
        </div>
      </div>
          </SwiperSlide>


         
         




          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">MU20 Summit Aftermovie</h4>
        <video
         data-iframe-id="1"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/summitpillarpage.mp4" type="video/mp4" />
        </video>
        {shownIframe === '1' && (
  <iframe
    className="video-iframe"
    data-iframe-id="1"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/yL1OyZ3-PYY?si=KYrxMvXOWBRxIcljs&autoplay=1" // Corrected URL
    title="YouTube video player 1"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 High School Summit | Asia's Biggest High School Leadership Conference | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>





          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Pathways Aftermovie</h4>
        <video
         data-iframe-id="2"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/pathwaysmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '2' && (
  <iframe
    className="video-iframe"
    data-iframe-id="2"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/rnEspqnT68k?si=6MdzW69ArLUdCPzl&autoplay=1" // Corrected URL
    title="YouTube video player 2"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 Pathways Residential Programme 2024 | Pathways World School | Aftermovie
          </p>
          <button className="readMoreIconBtn"   data-iframe-id="2" onClick={handleVideoClick} >
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>

          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">MGD LAB Aftermovie</h4>
        <video
         data-iframe-id="3"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '3' && (
  <iframe
    className="video-iframe"
    data-iframe-id="3"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/cjuBSu2Qtk0?si=nCdmzfaE9ASPHpdG&autoplay=1" // Corrected URL
    title="YouTube video player 3"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Maharani Gayatri Devi Girls' School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="3" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>

          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Doon LAB Aftermovie</h4>
        <video
         data-iframe-id="4"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '4' && (
  <iframe
    className="video-iframe"
    data-iframe-id="4"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/3ylVNxFkU6A?si=K_UhHRUW4TNZjUJG&autoplay=1" // Corrected URL
    title="YouTube video player 4"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          Leadership and Adventure Bootcamp | The Doon School, Dehradun Cohort | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="4" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>



          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">EHIS LAB Aftermovie</h4>
        <video
         data-iframe-id="5"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '5' && (
          <iframe
            className="video-iframe"
            data-iframe-id="5"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/MmFXW0yUxNw?si=vz7D1B3ThEQeUb2d&autoplay=1" // Added autoplay=1
            title="YouTube video player 5"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Emerald Heights International School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="5" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>



          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Mayo LAB Aftermovie</h4>
        <video
         data-iframe-id="6"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '6' && (
          <iframe
            className="video-iframe"
            data-iframe-id="6"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/NWeAlnmTfq8?si=nxq9bchKRPpL68IH&autoplay=1" // Added autoplay=1
            title="YouTube video player 6"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Mayo College Girls' School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="6" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
         
        </div>
      </div>
          </SwiperSlide>


          
          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">MU20 Summit Aftermovie</h4>
        <video
         data-iframe-id="1"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/summitpillarpage.mp4" type="video/mp4" />
        </video>
        {shownIframe === '1' && (
  <iframe
    className="video-iframe"
    data-iframe-id="1"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/yL1OyZ3-PYY?si=KYrxMvXOWBRxIcljs&autoplay=1" // Corrected URL
    title="YouTube video player 1"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 High School Summit | Asia's Biggest High School Leadership Conference | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>





          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Pathways Aftermovie</h4>
        <video
         data-iframe-id="2"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/pathwaysmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '2' && (
  <iframe
    className="video-iframe"
    data-iframe-id="2"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/rnEspqnT68k?si=6MdzW69ArLUdCPzl&autoplay=1" // Corrected URL
    title="YouTube video player 2"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 Pathways Residential Programme 2024 | Pathways World School | Aftermovie
          </p>
          <button className="readMoreIconBtn"   data-iframe-id="2" onClick={handleVideoClick} >
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>

          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">MGD LAB Aftermovie</h4>
        <video
         data-iframe-id="3"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '3' && (
  <iframe
    className="video-iframe"
    data-iframe-id="3"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/cjuBSu2Qtk0?si=nCdmzfaE9ASPHpdG&autoplay=1" // Corrected URL
    title="YouTube video player 3"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Maharani Gayatri Devi Girls' School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="3" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>

          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Doon LAB Aftermovie</h4>
        <video
         data-iframe-id="4"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '4' && (
  <iframe
    className="video-iframe"
    data-iframe-id="4"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/3ylVNxFkU6A?si=K_UhHRUW4TNZjUJG&autoplay=1" // Corrected URL
    title="YouTube video player 4"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          Leadership and Adventure Bootcamp | The Doon School, Dehradun Cohort | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="4" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>



          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">EHIS LAB Aftermovie</h4>
        <video
         data-iframe-id="5"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '5' && (
          <iframe
            className="video-iframe"
            data-iframe-id="5"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/MmFXW0yUxNw?si=vz7D1B3ThEQeUb2d&autoplay=1" // Added autoplay=1
            title="YouTube video player 5"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Emerald Heights International School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="5" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
        </div>
      </div>
          </SwiperSlide>



          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Mayo LAB Aftermovie</h4>
        <video
         data-iframe-id="6"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '6' && (
          <iframe
            className="video-iframe"
            data-iframe-id="6"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/NWeAlnmTfq8?si=nxq9bchKRPpL68IH&autoplay=1" // Added autoplay=1
            title="YouTube video player 6"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Mayo College Girls' School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="6" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
         
        </div>
      </div>
          </SwiperSlide>
  
        </Swiper>

      </section>







           

            <section className="programsSec bglightBlack p-80-20 benefits">
                <div className="container">
                    <div className="program-outer">
                        <div className="program-innerRight">
                            <h3 className="fs-34 fw-600 pb-20 textOrg">Benefits of being an MU20 Member Schools</h3>
                            <p className="fs-16 fw-400 textWhite">By connecting students from various backgrounds and schools, our flagship programmes engage students and actively involve them in the learning and development process.</p>

                            <h3 className="fs-34 fw-600 pb-20 textOrg">Free Structure</h3>
                            <ul className="freestructure">
                                <li>
                                    <p className="fs-16 fw-400 textWhite">INR 25,000 (including GST) per annum</p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite">Tim   </p>
                                 </li> 
                                 <li>
                                    <p className="fs-16 fw-400 textWhite">AFS Member School Fee has to be paid every year (April of each year)</p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite">INR 25,000 (including GST) per annum</p>
                                </li>
                            </ul>

                            <a href="#" className="registerBtn">Check Out The Calendar 2023-24 </a>
                            <a href="#" className="download-brochure"><img className="widthAuto"
                                src="assets/images/networkPage/download-logo.svg" alt="iconBtn" />Download Brochure</a>
                        </div>

                        <div className="program-innerLeft">
                            <img src="/assets/images/pillarPage/progarm.png" alt="pillarProgram" />
                        </div>

                    </div>

                </div>
            </section>

            <section className="p-80-20 bgBlack joinour-chain">
                <div className="container">
                    <div className="contactPopup bglightBlack ">
                        <div className="contactDetail-outer">
                            <div className="contactDetail">
                                <h3 className="fs-42 fw-700 textWhite pb-20">Join our chain of Schools</h3>
                                <p className="fs-18 fw-400 textWhite pb-20">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
                                    1500s, when an unknown printe</p>
                                <a className="textEmail fs-18 fw-400 textOrg" href="mailto:support@mu20.net"><span><img className="widthAuto"
                                    src="assets/images/homeImage/mailIcon.svg" alt="iconEmail" /> support@mu20.net</span>
                                </a>
                            </div>
                        </div>

                        <div className="contactForm">
                            <form>
                                <label htmlFor="fname">Name</label>
                                <input type="text" id="fname" placeholder="Name" />

                                <label htmlFor="cars">You are inquiring as a</label>
                                <select name="inquiring" id="inquiring">
                                    <option value="parent">Parent</option>
                                    <option value="school">School</option>
                                </select>

                                <label htmlFor="school-name">School Name</label>
                                <input type="text" id="school-name" placeholder="School Name (if you are a parent, kindly fill in your Ward's school name)" />

                                <div className="loaction">
                                    <label htmlFor="school-location">School Location</label>
                                    <input type="text" id="school-location" placeholder="School Location" />

                                    <label htmlFor="contact-number">Contact number</label>
                                    <input type="tel" id="contact-number" placeholder="Contact number" />
                                </div>

                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" placeholder="Email" />

                                <label htmlFor="message">Message</label>
                                <textarea id="message" placeholder="Add a message"></textarea>

                                <button type="submit" className="formSubmit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default NetworkSchool;  
