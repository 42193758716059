import React, {  useEffect, useRef, useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Helmet } from 'react-helmet';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";
import Popup from 'reactjs-popup';
import PopupForm from './PopupForm'; // Adjust the path as per your project structure


function Summits() {

  const scrollToSection = () => {
    const section = document.getElementById('programs_s');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };


    
  const [shownIframe, setShownIframe] = useState(null);

  // Function to handle button clicks
  const handleVideoClick = (event) => {
    const iframeId = event.target.getAttribute('data-iframe-id');
    setShownIframe(iframeId);
    
    // Reset and set src to play the video
    const iframes = document.querySelectorAll('.video-iframe');
    iframes.forEach(iframe => {
      if (iframe.getAttribute('data-iframe-id') === iframeId) {
        const src = iframe.src;
        iframe.src = ''; // Reset the src
        iframe.src = src; // Set it again to start playback
      }
    });
  };

    const [shownIframes, setShownIframes] = useState({});
  


   


















  const [showIframeLeft, setShowIframeLeft] = useState(false);
    const [showIframeRight, setShowIframeRight] = useState(false);

    const handleIconClickLeft = (e) => {
        e.preventDefault();
        setShowIframeLeft(true);
      };
    
      const handleIconClickRight = (e) => {
        e.preventDefault();
        setShowIframeRight(true);
      };
    
      const handleIframeCloseLeft = () => {
        setShowIframeLeft(false);
      };
    
      const handleIframeCloseRight = () => {
        setShowIframeRight(false);
      };









  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
      setShowPopup(!showPopup);
  };
  

    // const handleMouseEnter = (event) => {
    //     event.target.play();
    //   };   
    //   const handleMouseLeave = (event) => {
    //     event.target.pause();
    //   };
 
    const [isMuted, setIsMuted] = useState(true);
    const [isActive, setActive] = useState("false");

    const ToggleClass = () => {
        setActive(!isActive);
    };
  //Video on Hover Start
  

  const [showIframe, setShowIframe] = useState(false);
  const [playingVideos, setPlayingVideos] = useState(new Set());
  
  // Replace with actual logic to determine if the device is mobile or touch
  // Use window.matchMedia to determine if the viewport is mobile-sized
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  useEffect(() => {
    if (isMobile) {
      const videos = document.querySelectorAll('.video');
      const observerOptions = {
        root: null, // Use the viewport as the container
        rootMargin: '0px',
        threshold: 0.5, // Adjust this as needed
      };

      const handleIntersection = (entries) => {
        entries.forEach(entry => {
          const video = entry.target;
          if (entry.isIntersecting) {
            if (video.paused) {
              // Attempt to play the video and handle cases where play() might not return a Promise
              const playPromise = video.play();
              if (playPromise !== undefined) {
                playPromise.catch(error => console.error('Play interrupted:', error));
              }
              setPlayingVideos(prev => new Set(prev.add(video)));
            }
          } else {
            if (!video.paused) {
              // Attempt to pause the video and handle cases where pause() might not return a Promise
              const pausePromise = video.pause();
              if (pausePromise !== undefined) {
                pausePromise.catch(error => console.error('Pause interrupted:', error));
              }
              setPlayingVideos(prev => {
                const updated = new Set(prev);
                updated.delete(video);
                return updated;
              });
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, observerOptions);
      videos.forEach(video => {
        observer.observe(video);
      });

      return () => {
        videos.forEach(video => {
          observer.unobserve(video);
        });
      };
    }
  }, [isMobile, playingVideos]);

  const handleMouseEnter = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (video.paused) {
        // Attempt to play the video and handle cases where play() might not return a Promise
        video.muted = false; // Unmute the video
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => console.error('Play interrupted:', error));
        }
      }
    }
  };

  const handleMouseLeave = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (!video.paused) {
        // Attempt to pause the video and handle cases where pause() might not return a Promise
        const pausePromise = video.pause();
        if (pausePromise !== undefined) {
          pausePromise.catch(error => console.error('Pause interrupted:', error));
        }
        video.muted = true; // Mute the video
      }
    }
  };




  //Video on Hover End
    return (
        <>
           <Helmet>
        <title>MU20 Summit's        </title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
            <Header />
            <section className="oxfordBanner">
                {/* <div style="padding-bottom: 56.25%; max-width: 100%; position: relative;"><iframe
                    src="https://player.vimeo.com/video/347119375?autoplay=1&loop=1&muted=1&title=0&portrait=0&byline=0&keyboard=0"
                    width="800px" height="450px" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;"
                    frameborder="0"></iframe></div>   */}

                <div>
                    {/* <video className="sliderBgImage" autoPlay loop playsinline width="100%" muted={isMuted ? true : false}>
                        <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1711693095/MU_New_Site/iv3tdgjgarbau4qlm7h4.mp4" type="video/mp4" />
                    </video> */}
                    <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false}>
                        {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
                        <source src="https://musite.b-cdn.net/mu20summitmainpage.mp4" type="video/mp4" />
                    </video>
                </div>


                <div className="banner-content">
                    <div className='page_Heading'>
                        <h1>Summits</h1>
                        <p className='heading_cont'>Experience the pinnacle of high school events at our summits. Engage in thrilling competitions, connect with global peers, and unlock your leadership potential.
                        </p>  
                    </div>
                   
                    <div className={isActive ? "mute" : "unmute"}>
                        <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/mute.svg " alt="unMuteIcon" />
                            <img className="muteIcon" src="/assets/images/unmute.svg" alt="MuteIcon" /></a>
                    </div>
                </div>
            </section>

            <section className="boderGradient bgBlack p-80-20 pillarBoxSec">
                <div className="container pillarBox-outer">
                    <div className="pillarBox-innerLeft">
                        <div className="pillarBox ">
                        <div className='w-50'>
                        <img className="leftImg" src="assets/images/pillarimage/inspire.jpg" alt="pillarImg" />
                          </div>
                          
                            <div className="pillarBoxContent w-50">
                                <h4 className="fs-34 fw-600 pb-20 textOrg ">Inspire</h4>
                                <p className="fs-16 fw-400 textWhite">
                                Our summits are designed to ignite the spark of inspiration in every participant. Through engaging challenges, learning sessions, and dynamic forums, we bring together thought leaders and change-makers to share their stories and insights. Our goal is to inspire students to dream big, think creatively, and pursue their passions with unwavering determination.
                                </p>
                            </div>
                        </div>
                        <div className="pillarBox Innovate m-0">
                            <div className="pillarBoxContent w-50">
                                <h4 className="fs-34 fw-600 pb-20 textOrg ">Innovate
                                </h4>
                                <p className="fs-16 fw-400 textWhite">
                                Innovation is at the heart of our summits. We provide a platform where students can explore cutting-edge ideas, develop innovative solutions, and challenge the status quo. With a diverse range of challenges, our summits allow participants to delve into various fields, from technology and entrepreneurship to arts and sciences. This focus on hands-on learning and real-world applications encourages students to push boundaries and think outside the box, fostering a culture of creativity and progress across multiple disciplines.
                                </p>
                            </div>

                            <div className='w-50'>
                               <img className="rightImg" src="assets/images/pillarimage/inovate.png" alt="pillarImg" />
                            </div>
                           
                        </div>
                    </div>
                    <div className="pillarBox-innerRight">
                        <div className="pillarBox m-0">
                            <img className="topImg" src="assets/images/pillarimage/impact.jpg" alt="pillarImg" />
                            <div className="pillarBoxContent">
                                <h4 className="fs-34 fw-600 pb-20 textOrg ">Impact
                                </h4>
                                <p className="fs-16 fw-400 textWhite">
                                Our summits aim to create a lasting impact on both individuals and communities. By addressing pressing global issues and promoting actionable solutions, we empower students to become agents of change. Through collaborative projects, policy simulations, social initiatives, and diverse challenges in fields like entrepreneurship, theatrics and technology participants gain a wide range of skills and knowledge. This multifaceted approach ensures that students are well-equipped to make a meaningful difference in the world, whether they're developing innovative business ideas, crafting compelling narratives, or driving policy change.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="programsSec bglightBlack  p-80-20" id='programs_s'>
                <div className="container">
                    <h2 className="fs-80 fw-600 textWhite">Programs</h2>
                    <div className="program-outer">
                        <div className="program-innerLeft">
                                <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                          <source src="https://musite.b-cdn.net/mu20summitmainpage.mp4" type="video/mp4" />
                        </video>
                        </div>
                        <div className="program-innerRight">
                            <h3 className="fs-34 fw-600 textOrg ">MU20 Opportunity Summit 2024 <br/>
 </h3>  
                          <div className='date_bold'>
                          <span className='fs-30 '>19, 20, 21 OCT 2024</span>
                          </div>
                          
                          <br/>
                            <p className="fs-16 fw-400 textWhite pb-20">
                            Welcome to the MU20 Opportunity Summit 2024, Asia's Biggest high school conference. This year, join over 3000+ brilliant minds from around the globe for an unforgettable journey of innovation, inspiration, and impact. Experience transformative challenges, visionary leaders, and groundbreaking ideas. Be part of the future of leadership at the MU20 Opportunity Summit 2024. Don’t miss this epic celebration of creativity and collaboration.

                            </p>
                            <h4 className="fs-18 fw-400 textWhite"><span className="textOrg">Venue :</span>The Emerald Heights International School, Indore and NMIMS, Indore
                            </h4>
                            <ul className="progarmDetailList">
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Location.svg" alt="Location" />
                                        Location:<span className="fs-18 fw-400">Indore</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Certification.svg" alt="Certification" />
                                        Certification:<span className="fs-18 fw-400">Yes</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Duration.svg" alt="Duration" />
                                        Duration:<span className="fs-18 fw-400">3 days </span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Reidential.svg" alt="Reidential" />
                                        Participations:<span className="fs-18 fw-400">3000+</span></h6>
                                </li>
                                {/* <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Reidential.svg" alt="Reidential" />
                                        Reidential<span className="fs-18 fw-400">Team-building exercises...</span></h6>
                                </li> */}
                            </ul>

                            <a className="registerBtn" href='/MU20OpportunitySummit24'>
                           Explore <img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                            </a>
                            <button className="registerBtn joinbtn"  onClick={togglePopup} >
                            Join now 
                            </button>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}
                           
                            {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn">Join now<img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            {/* <a href="/MU20OpportunitySummit24" className="readMoreBtn">Read More</a> */}
                        </div>
                    </div>

                    <div className="program-outer">
                        <div className="program-innerLeft">
                                <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                          <source src="https://musite.b-cdn.net/bannervideoCom.mp4" type="video/mp4" />
                        </video>
                        </div>
                        <div className="program-innerRight">
                            <h3 className="fs-34 fw-600  textOrg ">OxfordMUN India 2024
                            </h3>

                            <div className='date_bold'>
                          <span className='fs-30 '>9, 10, 11 AUG 2024</span>
                          </div>
                              <br/>
                          
                            <p className="fs-16 fw-400 textWhite pb-20">
                            OxfordMUN India 2024 marked a historic moment as we brought the esteemed OxfordMUN experience to India for the very first time. With over 500 students from 50+ top schools across the country, this inaugural conference was an incredible fusion of academic excellence and cultural exchange. The event was a true celebration of diplomacy, debate, and leadership, set against the backdrop of the picturesque FLAME University. Over the course of three days, participants engaged in thought-provoking discussions, tackled global issues, and formed lasting connections, all while creating memories that will resonate for years to come. OxfordMUN India 2024 was not just a conference, but a landmark event in the journey of young leaders.
                            </p>
                            <h4 className="fs-18 pb-20 fw-400 textWhite"><span className="textOrg">Venue:</span>FLAME University, Pune
                            </h4>

                            <ul className="progarmDetailList">
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Location.svg" alt="Location" />
                                        Location:<span className="fs-18 fw-400">Pune</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Certification.svg" alt="Certification" />
                                        Certification:<span className="fs-18 fw-400">Yes</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Duration.svg" alt="Duration" />
                                        Duration:<span className="fs-18 fw-400">3 days </span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Reidential.svg" alt="Reidential" />
                                        Participations:<span className="fs-18 fw-400">500+</span></h6>
                                </li>
                                {/* <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Reidential.svg" alt="Reidential" />
                                        Reidential<span className="fs-18 fw-400">Team-building exercises...</span></h6>
                                </li> */}
                            </ul>

                           

                            <a className="registerBtn" href='/oxfordmun'>
                           Explore <img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                            </a>
                            <button className="registerBtn joinbtn"  onClick={togglePopup} >
                            Join now 
                            </button>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}
                           
                            {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn">Join now<img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            {/* <a href="/MU20OpportunitySummit24" className="readMoreBtn">Read More</a> */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="bgBlack testimonialSec testimonialHomeSec">
        <div className="container-full">
          <div className="topHeading">
          
            <h2 className="fs-80 fw-600 textWhite">Testimonials</h2>
          </div>

          <Swiper
  breakpoints={{
    1440: {
      spaceBetween: 50,
      slidesPerView: 4,
      centeredSlides: true, // Center the active slide
    },
    768: {
      spaceBetween: 30,
      slidesPerView: 3,
      centeredSlides: true, // Center the active slide
    },
    200: {
      spaceBetween: 20,
      slidesPerView: 1,
      centeredSlides: true, // Center the active slide
      autoplay: false,
    },
  }}
  slidesOffsetAfter={200}
 slidesOffsetBefore={200}
  modules={[Autoplay, A11y]}
  spaceBetween={50}
  slidesPerView={5}
  loop={true}
  // autoplay={{
  //   delay: 1500,
  //   disableOnInteraction: false,
  //   pauseOnMouseEnter: true,
  // }}
  centeredSlides={true} // Center the active slide
>
  <SwiperSlide>
    <div className="testimonialImg">
      <video
        className="testimonialVideo video"
        controls
        muted
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        playsInline
      >
        <source src="https://musite.b-cdn.net/videos/summit_web_test.mp4" type="video/mp4" />
      </video>
      <div className="textWrapper">
        <img src="/assets/images/videoSec.svg" alt="home-img" />
        <h6 className="titleWatch">Watch Story</h6>
      </div>
    </div>
  </SwiperSlide>


  <SwiperSlide>
    <div className="testimonialImg">
      <video
        className="testimonialVideo video"
        controls
        muted
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        playsInline
      >
        <source src="https://musite.b-cdn.net/Summittest2.mp4" type="video/mp4" />
      </video>
      <div className="textWrapper">
        <img src="/assets/images/videoSec.svg" alt="home-img" />
        <h6 className="titleWatch">Watch Story</h6>
      </div>
    </div>
  </SwiperSlide>
  
</Swiper>

        </div>
      </section>


 
            {/* <section className="p-80-20 bglightBlack networkSchoolSec">
                <div className="container">
                    <div className="topHeading">
                        <h2 className="fs-80 fw-600 textWhite">Participating Schools</h2>
                    </div>
                    <div className="networkSchoolSlider-outer">
                    <Swiper
                            breakpoints={{
                                1440: {
                                    spaceBetween: 30,
                                    slidesPerView: 4
                                },
                                768: {
                                    spaceBetween: 30,
                                    slidesPerView: 3,
                                },
                                200: {
                                    spaceBetween: 20,
                                    slidesPerView: 1,
                                }
                            }}
                          
                            modules={[Navigation, Pagination, Autoplay, A11y]}
                            spaceBetween={30}
                            slidesPerView={4}
                            navigation={false}
                            loop={true}
                            autoplay={{
                                delay: 2500, 
                                disableOnInteraction: false 
                            }}
                            centeredSlides={false}
                            pagination={{ clickable: true }}
                        >
                            <SwiperSlide>
                                <div className="networkSchoolSlider">
                                    <img src="assets/images/homeImage/networkSch1.svg" alt="networkSch" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="networkSchoolSlider">
                                    <img src="assets/images/homeImage/networkSch2.svg" alt="networkSch" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="networkSchoolSlider">
                                    <img src="assets/images/homeImage/networkSch3.svg" alt="networkSch" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="networkSchoolSlider">
                                    <img src="assets/images/homeImage/networkSch4.svg" alt="networkSch" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="networkSchoolSlider">
                                    <img src="assets/images/homeImage/networkSch4.svg" alt="networkSch" />
                                </div>
                            </SwiperSlide>
                    </Swiper>

                    </div>
                </div>
            </section> */}




<section className="p-80-20 bglightBlack networkSchoolSec">
        <div className="container">
          <div className="topHeading">
            <h2 className="fs-80 fw-600 textWhite">Participating Schools</h2>
           
          </div>
          <div className="networkSchoolSlider-outer">
            <Swiper
              modules={[Navigation, Autoplay, A11y]}
              spaceBetween={30}
              slidesPerView={'auto'}
               cssMode={true}              
              centerInsufficientSlides={false}
              navigation={false}
              loop={true}            
              autoplay={{
                delay: 1000,  // Set delay to 0 to start autoplay immediately
                disableOnInteraction: true,  // Disable autoplay when user interacts with slides
                pauseOnMouseEnter: true  // Pause autoplay on mouse enter
              }}
              freeMode={true} 
              centeredSlides={false}
            >
              

              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/always.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/ashoka.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bharati.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bihani.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/billabong.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/birla.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bk_birla.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/crossroads.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/dalimess.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/delhi.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/delhiworld.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/dolphin.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/doon.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/doon_girl.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/duty.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/emrald.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/goenka.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/golden.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/him.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/ies.png" alt="networkSch" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/inventure.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jayshree.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jbcn.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jindal.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jodhamal.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jyoti.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/kasiga.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/kunska.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/macro.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/mann.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/mcd.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/no.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pacific.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pathways.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pinegrove.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/queen.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajkumar.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajkumar_college.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajmas.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajmata.png" alt="networkSch" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rockwoods.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/sagar_school.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/sanskar.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/satpuda.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/satyasai.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/school.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/scindia.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/shishukunj.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/singapoor.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/strength.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/timor.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/truth.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/uwc.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/valistus.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vantage.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vidhya.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/virtus.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vivek.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/wisdom.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/witty.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/world.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/zabar.png" alt="networkSch" />
                </div>
              </SwiperSlide>

            </Swiper>
          </div>
        </div>
      </section>










            <section className=" bglightBlack  p-80-20 opportunitiesSec">
                <div className="container opportunities-outer">
                    <div className="opportunities-inner">
                        <div className="opportunities-wrapper purpleGradient">
                            <img src="assets/images/6000+student.jpg" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite pb-10">600,000+ STUDENTS REACHED
                                </h6>
                                <p className="fs-16 fw-400 textWhite pb-20">An extensive series of pre-conference orientations were conducted over three months across schools nationwide, engaging a vast audience.</p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                        <div className="opportunities-wrapper yellowGradient">
                            <img src="assets/images/summits_grid/2.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite  pb-10">300+ SCHOOLS ENGAGED
                                </h6>
                                <p className="fs-16 fw-400 textWhite pb-20">Through AFS and MU20 School of Opportunity’s expansive school network, the summit connected with a diverse array of educational institutions during pre-conference interactions.</p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                    </div>
                    <div className="opportunities-inner">
                        <div className="opportunities-wrapper orgGradient">
                            <img src="assets/images/summits_grid/3.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite pb-10">6 COUNTRIES REPRESENTED
                                </h6>
                                <p className="fs-16 fw-400 textWhite pb-20">The summit was a melting pot of global perspectives, with participants from over six countries discussing some of the world’s most pressing issues.
                                </p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                        <div className="opportunities-wrapper blueGradient">
                            <img src="assets/images/summits_grid/4.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite  pb-10">2500+ YOUNG VISIONARIES ON CAMPUS
                                </h6>
                                <p className="fs-16 fw-400 textWhite pb-20">A signiﬁcant gathering of youth came together to be part of this transformative movement.
                                </p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                    </div>
                    <div className="opportunities-inner">
                        <div className="opportunities-wrapper redGradient">
                            <img src="assets/images/summits_grid/5.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite pb-10">24+ COMMITTEES AND AGENDAS</h6>
                                <p className="fs-16 fw-400 textWhite pb-20">The summit ensured inclusivity and breadth in discussions, covering a wide range of  topics crucial for the current generation.
                                </p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                        <div className="opportunities-wrapper greenGradient">
                            <img src="assets/images/200+coordinate.jpg" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite  pb-10">200+ SCHOOL COORDINATORS COLLABORATING</h6>
                                <p className="fs-16 fw-400 textWhite pb-20"><p className="fs-16 fw-400 textWhite pb-20">The summit ensured inclusivity and breadth in discussions, covering a wide range of  topics crucial for the current generation.
                                </p> </p>
                                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="callToAction bgBlack callToActionPillar ">
                <div className="container  ">
                    <div className="  callToAction-inner">
                        <div className="row">
                            <div className="column callToActionLeft">
                                <h5>Join us for a <br />Great experience</h5>
                                {/* <p>Register your school today to the Oxford MUN India 2024.</p> */}
                            </div>
                            <div className="column callToActionRight">
                                <p>Register for the MU20 Opportunity Summits today and engage in thrilling competitions. Visit the link below to secure your spot and embark on an unforgettable journey of discovery, diplomacy, and leadership.</p>
                                
                                <button className="registerBtn" onClick={scrollToSection}>
  Explore Now
  {/* <img className="widthAuto" src="assets/images/pillarPage/ArrowOrg.svg" alt="iconBtn" /> */}
</button>


                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                </Popup>
                            )}
                                
                                
                                {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn">Join now<img className="widthAuto"
                                    src="assets/images/pillarPage/ArrowOrg.svg" alt="iconBtn" /></a> */}
                                
                                {/* <a href="#" className="registerBtn"> Register Now </a>  */}
                            </div>
                        </div>
                    </div>

                </div>
            </section>


          
            <section className="p-80-20 bgBlack   pastProgVideoSec">
        <div className="container-full">
          <div className="topHeading">
            <h2 className="fs-80 fw-600 textWhite">Summits Aftermovies</h2>
          </div>
        </div>

        <Swiper 
              // breakpoints={{
              //   1440: {
              //     spaceBetween: 30,
              //     slidesPerView: 4,
              //   },
              //   768: {
              //     spaceBetween: 30,
              //     slidesPerView: 2,
              //   },
              //   200: {
              //     spaceBetween: 15,
              //     slidesPerView: 1,
              //   }
              // }}




           modules={[Pagination, A11y, Autoplay]}
           spaceBetween={30}
           slidesPerView={1}
            speed={1000}
           slidesOffsetBefore={100}
          //  pagination={{ clickable: true }}
           loop={true}  // Uncomment this line if you want the slides to loop
           freeMode={true}
           autoplay={{
             delay: 1500,
             disableOnInteraction: false,  
             pauseOnMouseEnter: true
           }}
           centeredSlides={true}
           initialSlide={3} 

            breakpoints={{
        // when window width is >= 200px
        200: {
          slidesPerView: 1,
          spaceBetween: 15,
          autoplay: false,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        // when window width is >= 1440px
        1440: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      }}
         
        >
      

       
          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">IWS’22 Aftermovie </h4>
        <video
         data-iframe-id="3"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/videos/iws_summit.mp4" type="video/mp4" />
        </video>

        {shownIframe === '3' && (
  <iframe
    className="video-iframe"
    data-iframe-id="3"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/Bk4svIGUgZk?si=O2vzSPstrlHVz7Co&autoplay=1" // Corrected URL
    title="YouTube video player 3"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 / IWS '22 | India's Biggest High School Conference | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="3" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
         
        </div>
      </div>
          </SwiperSlide>

          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">MU20 Highschool Summit 2023 Aftermovie </h4>
        <video
         data-iframe-id="4"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/mu20summitmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '4' && (
  <iframe
    className="video-iframe"
    data-iframe-id="4"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/yL1OyZ3-PYY?si=ee5rDO1HyjO6Vfrh&autoplay=1" // Corrected URL
    title="YouTube video player 4"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 High School Summit | Asia's Biggest High School Leadership Conference | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="4" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          
        </div>
      </div>
          </SwiperSlide>

          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">IWS’22 Aftermovie</h4>
        <video
         data-iframe-id="3"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/videos/iws_summit.mp4" type="video/mp4" />
        </video>

        {shownIframe === '3' && (
  <iframe
    className="video-iframe"
    data-iframe-id="3"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/Bk4svIGUgZk?si=O2vzSPstrlHVz7Co&autoplay=1" // Corrected URL
    title="YouTube video player 3"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 / IWS '22 | India's Biggest High School Conference | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="3" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
         
        </div>
      </div>
          </SwiperSlide>

          <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">MU20 Highschool Summit 2023 Aftermovie </h4>
        <video
         data-iframe-id="4"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/mu20summitmainpage.mp4" type="video/mp4" />
        </video>

        {shownIframe === '4' && (
  <iframe
    className="video-iframe"
    data-iframe-id="4"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/yL1OyZ3-PYY?si=ee5rDO1HyjO6Vfrh&autoplay=1" // Corrected URL
    title="YouTube video player 4"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 High School Summit | Asia's Biggest High School Leadership Conference | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="4" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          
        </div>
      </div>
          </SwiperSlide>

        


         
        </Swiper>

      </section>





            <Footer />
            
        </>
    )
}

export default Summits